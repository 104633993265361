import React from 'react';
import './loader.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const Loader = ({size: Size, text: Text}) => (
    <span>
        <FontAwesomeIcon icon={faSpinner} size={Size} className="loader" spin={true} />
        {typeof(Text) !== 'undefined' && Text !== "" ?(
            <>{" "+Text}</>
        ):(
            <></>
        )}
    </span>
    
);

export default Loader;