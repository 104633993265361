import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from './components/FirebaseAuth';
import RefreshButton from './RefreshButton';

import PrivateRoute from './components/routers/PrivateRoute';
import PublicTemplateDark from './components/templates/PublicTemplateDark';
import AccountTemplate from './components/templates/AccountTemplate';
import ActiveAccountTemplate from './components/templates/ActiveAccountTemplate';

import SignIn from './pages/public/SignIn';
import Home from './pages/auth/Home';
import PageNotFound from './pages/public/PageNotFound';
import AppTemplate from './components/templates/AppTemplate';
import UserProfile from './pages/auth/user/UserProfile';
import UpdateEmail from './pages/auth/user/UpdateEmail';
import UpdateName from './pages/auth/user/UpdateName';
import VerifyEmail from './pages/auth/user/VerifyEmail';
import UpdatePassword from './pages/auth/user/UpdatePassword';
import UpdatePhone from './pages/auth/user/UpdatePhone';
import DeleteUser from './pages/auth/user/DeleteUser';
import ViewLogs from './pages/auth/user/ViewLogs';
import Secrets from './pages/auth/user/Secrets';
import Plans from './pages/auth/accounts/Plans';
import NewAccount from './pages/auth/accounts/NewAccount';


// load stripe
import { stripeConfig } from "./inc/stripe";
import { Elements } from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import Overview from './pages/auth/accounts/Overview';
import APISettings from './pages/auth/accounts/APISettings';
import Registrations from './pages/auth/accounts/Registrations';
import Logs from './pages/auth/accounts/Logs';
import UserList from './pages/auth/accounts/UserList';
import UserRole from './pages/auth/accounts/UserRole';
import AddUser from './pages/auth/accounts/AddUser';
import Invite from './pages/auth/user/Invite';
import PaymentList from './pages/auth/accounts/PaymentList';
import PaymentMethod from './pages/auth/accounts/PaymentMethod';
import DeleteAccount from './pages/auth/accounts/DeleteAccount';
import PrioritySupport from './pages/auth/accounts/PrioritySupport';
import PastDue from './pages/auth/accounts/PastDue';
const stripePromise = loadStripe(stripeConfig.public_api_key);


function App() {
    return (
        <Elements stripe={stripePromise}>
            <AuthProvider>
                <Router>
                    <Routes>
                        <Route element={<PrivateRoute/>}>
                            <Route exact path="/secrets"                                         element={<AppTemplate title="Share Secrets" ><Secrets /></AppTemplate>}/>
							<Route exact path="/"                                                element={<AppTemplate title="My Accounts" ><Home/></AppTemplate>} />
							<Route exact path="/account/:accountId/past_due"                     element={<AppTemplate title="Bill Past Due" roles={["*"]}><PastDue/></AppTemplate>}  />

							<Route exact path="/account/:accountId/billing/plan"                 element={<AccountTemplate title="Create Your Plan" roles={["admin"]}><Plans/></AccountTemplate>} />
							<Route exact path="/account/:accountId/billing/payment-method"       element={<AccountTemplate title="Update Payment Method" roles={["admin"]}><PaymentMethod/></AccountTemplate>} />
							<Route exact path="/account/:accountId/billing/delete"               element={<AccountTemplate title="Delete Account" roles={["admin"]}><DeleteAccount/></AccountTemplate>} />
							<Route exact path="/account/:accountId/billing"                      element={<AccountTemplate title="Billing" roles={["admin", "financier"]}><PaymentList/></AccountTemplate>} />
							<Route exact path="/account/:accountId/users/change/:userId"         element={<ActiveAccountTemplate title="Change Role" roles={["admin"]} ><UserRole/></ActiveAccountTemplate>} />
							<Route exact path="/account/:accountId/users"                        element={<ActiveAccountTemplate title="Users" roles={["admin"]}><UserList/></ActiveAccountTemplate>} />
							<Route exact path="/account/:accountId/users/add"                    element={<ActiveAccountTemplate title="Add User" roles={["admin"]}><AddUser/></ActiveAccountTemplate>} />
							<Route exact path="/account/:accountId/"                             element={<ActiveAccountTemplate title="Overview" roles={["*"]}><Overview/></ActiveAccountTemplate>} />
							<Route exact path="/account/:accountId/settings"                     element={<ActiveAccountTemplate title="API Settings" roles={["admin"]}><APISettings/></ActiveAccountTemplate>} />
							<Route exact path="/account/:accountId/registrations"                element={<ActiveAccountTemplate title="Registrations" roles={["registrar", "admin"]}><Registrations/></ActiveAccountTemplate>} />
							<Route exact path="/account/:accountId/logs"                         element={<ActiveAccountTemplate title="Logs" roles={["admin"]}><Logs/></ActiveAccountTemplate>} />
							<Route exact path="/account/:accountId/priority-support"             element={<ActiveAccountTemplate title="Priority Support" roles={["registrar", "admin"]} ><PrioritySupport/></ActiveAccountTemplate>} />

							<Route exact path="/new-account"                                     element={<AppTemplate title="Create New Account"><NewAccount/></AppTemplate>} />
							<Route exact path="/user/profile"                                    element={<AppTemplate title="User Profile"><UserProfile/></AppTemplate>} />
							<Route exact path="/invite/:code"                                    element={<AppTemplate title="View Invite"><Invite/></AppTemplate>} />
							<Route exact path="/user/profile/update-email"                       element={<AppTemplate title="Change Your Email"><UpdateEmail/></AppTemplate>} />
							<Route exact path="/user/profile/update-name"                        element={<AppTemplate title="Change Your Name"><UpdateName/></AppTemplate>} />
							<Route exact path="/user/profile/verify-email"                       element={<AppTemplate title="Verify Your Name"><VerifyEmail/></AppTemplate>} />
							<Route exact path="/user/profile/update-password"                    element={<AppTemplate title="Change Your Password"><UpdatePassword/></AppTemplate>} />
							<Route exact path="/user/profile/update-phone"                       element={<AppTemplate title="Change Your Phone Number"><UpdatePhone/></AppTemplate>} />
							<Route exact path="/user/profile/delete"                             element={<AppTemplate title="Delete Your Account"><DeleteUser/></AppTemplate>} />
							<Route exact path="/user/log"                                        element={<AppTemplate title="View Activity Logs"><ViewLogs/></AppTemplate>} />
                        </Route>
                        <Route exact path="/signin" element={<PublicTemplateDark title="Sign In"><SignIn /></PublicTemplateDark>} />
                        <Route element={<PublicTemplateDark title="Page Not Found"><PageNotFound/></PublicTemplateDark>} />
                    </Routes>
                </Router>
            </AuthProvider>
            <RefreshButton />
        </Elements>
    );
}

export default App;
