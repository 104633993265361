import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

const MultiSelect = (props) => {

    const {
        hasError,
        customErrorMessage,
        minLen,
        maxLen,
        required,
        error,
        changeHandler,
        options,
        disabled,
        ...others
    } = props;

    const [hasErrorState, setHasErrorState] = useState(hasError);
    const [errorMessage, setErrorMessage] = useState(error);

    useEffect(() => {
        setHasErrorState(hasError);
        setErrorMessage(error);
    }, [hasError, error]);

    return (
        <>
            <Select
                className={"form-multi-select form-control"+(hasErrorState?' is-invalid':'')}
                options={options}
                isSearchable
                isMulti
                components={animatedComponents}
                isDisabled={disabled}
                {...others}
                onChange={(e, action) => {
                let foundError = false;
                let foundErrorMessage = '';
                // validate required
                if(typeof(required) !== 'undefined' && required){
                    if(e.length === 0){
                        foundErrorMessage = 'You need to select at least one option.';
                        foundError = true;
                    }
                }

                // validate length
                if(!foundError && typeof(minLen) !== 'undefined' && minLen !== 0){
                    if(e.length < minLen){
                        foundErrorMessage = 'You must select at least '+minLen+' items.';
                        foundError = true;
                    }
                }
                if(!foundError && typeof(maxLen) !== 'undefined' && maxLen !== 0){
                    if(e.length > maxLen){
                        foundErrorMessage = 'You can only select at most '+maxLen+' items.';
                        foundError = true;
                    }
                }
                
                if(foundError){
                    setHasErrorState(true);
                    setErrorMessage(foundErrorMessage);
                }else{
                    setHasErrorState(false);
                }
                const values = e.map((item) => item.value);
                changeHandler({
                    hasError: foundError,
                    error: foundErrorMessage,
                    value: values
                });
            }}>
            </Select>
            {hasErrorState && 
                <div className="invalid-feedback">
                    {errorMessage}
                </div>
            }
        </>
    )
}

MultiSelect.propTypes = {
    name: PropTypes.string,
    customErrorMessage: PropTypes.string,
    hasError: PropTypes.bool,
    error: PropTypes.string,
    minLen: PropTypes.number,
    maxLen: PropTypes.number,
    required: PropTypes.bool,
    changeHandler: PropTypes.func,
    disabled: PropTypes.bool,
    options: PropTypes.array
}

export default MultiSelect;