
import React from "react";
import { Field, InputSubdomain } from "../Form";
import { Form, Modal } from "react-bootstrap";

const SubdomainField = (props) => {
  const {
    services,
    settingsCommitted,
    subdomainName,
    setSubdomainName,
    zoneName,
    zoneOptions
  } = props;

  const subdomainInputElement = React.useRef(null);
  const [locked, setLocked] = React.useState(false);
  const [showDialog, setShowDialog] = React.useState(false);
  const [question1, setQuestion1] = React.useState(false);
  const [question2, setQuestion2] = React.useState(false);
  const [question3, setQuestion3] = React.useState(false);
  const [domain, setDomain] = React.useState(".ltiaas.com");

  function customDomainChangeHandler(event) {
    let new_val = event.value;
    let foundErrorMessage = "";
    let foundError = false;
    
    // validate the value against validation regex
    if (!RegExp("^((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\\.?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\\.?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\\.(xn--)?([a-z0-9\\-]{1,61}|[a-z0-9-]{1,30}\\.[a-z]{2,})$").test(new_val)) {
      foundErrorMessage = "Must be a valid domain or subdomain name. For example: lti.mytool.com";
      foundError = true;
    }

    if(!foundError && new_val.endsWith(".ltiaas.com")) {
      validateSubdomain(new_val)
    } else {
      setSubdomainName({
        value: new_val,
        hasError: foundError,
        error: foundErrorMessage
      });
    }
  }

  function subdomainKeyDownHandler(event) {
    event.preventDefault();
    const  val = subdomainName.value;
    const start = val.indexOf(domain);
    let new_val = "";

    if (event.keyCode === 8) {
      //backspace
      if (start > 0) {
        new_val = val.substring(0, start - 1) + domain;
      } else {
        new_val = subdomainName.value
      }
    } else if(event.key.length === 1){
      new_val = val.substring(0, start) + event.key + domain;
    } else {
      new_val = val;
    }
    validateSubdomain(new_val);
  }

  function validateSubdomain(new_val) {
    let foundError = false;
    let foundErrorMessage = "";
    // validate required
    if (new_val.trim().length === 0) {
      foundErrorMessage = "This is a required field.";
      foundError = true;
    }

    if (new_val.length > 61) {
      foundErrorMessage =
        "The input must be no more than " + 61 + " characters.";
      foundError = true;
    }

    // validate the value against validation regex
    if (!RegExp(`^[a-z0-9\\-\\.]{3,60}${domain}$`).test(new_val)) {
      foundErrorMessage = `Must be a subdomain of ${domain}. i.e.: mytool${domain} where the subdomains is 3-50 characters.`;
      foundError = true;
    }

    setSubdomainName({
      value: new_val,
      hasError: foundError,
      error: foundErrorMessage
    });
  }

  React.useEffect(() => {
    if(services.customSubdomain && subdomainName.value.length > 0 && zoneOptions.length > 0) {
      customDomainChangeHandler(subdomainName);
    }
  }, [zoneName.value])

  React.useEffect(() => {
    let val = subdomainName.value;
    if(val && !services.customSubdomain) {
      const foundZone = zoneOptions.find(z => z.value === zoneName.value)
      const foundDomain = foundZone ? foundZone.prefix : ".ltiaas.com";
      let start = val.indexOf(foundDomain);
      subdomainInputElement.current.selectionStart = start;
      subdomainInputElement.current.selectionEnd = start;
      setDomain(foundDomain);
    } 
  }, [subdomainName, services.customSubdomain, zoneName.value]);


  React.useEffect(() => {
    if(settingsCommitted) {
      setLocked(true);
    }
  }, [settingsCommitted])

  const requestUnlock = (value) => {
    if(value === true) {
      setShowDialog(true);
    } else {
      setLocked(true);
    }
  }

  return (
    <>
      <Field
        label={services.customSubdomain ? "Domain Name" : "Subdomain"}
        description={services.customSubdomain ? 
        `This is the URL that we provision for your tool in our system. All of your API traffic will go through this URL. i.e. lti.mytool.com  (or mytool${domain}). `
        :
        `This is the URL that we provision for your tool in our system. All of your API traffic will go through this URL. It is a subdomain of ltiaas.com. i.e. mytool${domain}.`
        }
      >
        <InputSubdomain
          type="text"
          value={subdomainName.value}
          name="subdomain-name"
          maxLen={services.customSubdomain ? 200 : 61}
          disabled={settingsCommitted && locked}
          locked={locked}
          requestUnlock={requestUnlock}
          error={subdomainName.error}
          hasError={subdomainName.hasError}
          required={true}
          changeHandler={services.customSubdomain ? customDomainChangeHandler : setSubdomainName}
          keyDownHandler={services.customSubdomain ? ()=>{} : subdomainKeyDownHandler}
          ref={subdomainInputElement}
        />
      </Field>
      <Modal
        show={showDialog}
        onHide={() => {
          setShowDialog(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Unlock Domain</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to change the domain of this account? If you change the domain, this means:
          <Form>
            {!services.customSubdomain &&
            <Form.Check className="checkbox-xl" id="q1" label="Other customers/accounts will be able to use your old domain." onChange={(e)=>setQuestion1(e.target.checked)}/>
            }
            <Form.Check className="checkbox-xl" id="q2" label="Any existing LMS registrations will no longer work, because LTI registrations are tied to the domain." onChange={(e)=>setQuestion2(e.target.checked)}/>
            {services.customSubdomain &&
              <Form.Check className="checkbox-xl" id="q3" label="You will need to restart the custom domain validation process for the new domain." onChange={(e)=>setQuestion3(e.target.checked)}/>
            }
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button 
            type="button"
            className="btn btn-danger"
            disabled={!((services.customSubdomain || question1) && question2 && (!services.customSubdomain || question3))}
            onClick={e => {
              e.preventDefault();
              setLocked(false);
              setShowDialog(false);
            }}
          >
            Unlock
          </button>
          <button 
            type="button"
            className="btn btn-secondary"
            onClick={e => {
              e.preventDefault();
              setShowDialog(false);
            }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default SubdomainField;