// A react component that gets an API Key
import React from "react";
import { CloudFunctions } from "../FirebaseAuth/firebase";
import { AuthContext } from "../FirebaseAuth";
import Loader from '../Loader';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Button, Modal } from "react-bootstrap";
import Alert from "../Alert";

const ApiKey = (props) => {

  const {
    settingsCommitted,
    deploymentMethod,
    accountApiKey
  } = props;

  const [errorMessage, setErrorMessage] = React.useState(null);
  const [inRotate, setInRotate] = React.useState(false);
  const [apiKey, setApiKey] = React.useState(null);
  const [isCopied, setIsCopied] = React.useState(false);
  const [showDialog, setShowDialog] = React.useState(false);
  const { userData } = React.useContext(AuthContext);

  React.useEffect(() => {
    if (accountApiKey !== null) {
      setApiKey(accountApiKey);
    }
  },[accountApiKey]);

  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const rotateApiKey = async () => {
    setInRotate(true);
    setShowDialog(false);
    const onCall = CloudFunctions.httpsCallable('oncall');
    onCall({
      name: "rotateApiKey",
      data: {
        accountId: userData.currentAccount.id
      }
    }).then(res => {
      if(res.data.hasOwnProperty("error")) {
        setErrorMessage(`${res.data.error}, Error ID:${res.data.details.errorId} `);
        setInRotate(false);
        return;
      }
      setApiKey(res.data.apiKey);
      setInRotate(false);
    }).catch(err => {
      setErrorMessage(err.message);
      setInRotate(false);
    });
  }

  return (
    <>
      { settingsCommitted && apiKey !== null &&
      <>
        <div className="card">
          <div className="card-header">API Key</div>
          <div className="card-body">
            {errorMessage !== null && (
              <Alert
                  type="danger"
                  message={errorMessage}
                  dismissible={true}
                  onDismiss={() => setErrorMessage(null)}
              ></Alert>
            )}
            {apiKey !== "" && apiKey !== null && settingsCommitted && 
              <>
                <p>
                  Use this PRIVATE API KEY when <a href="https://ltiaas.com/guides/api/authentication" target="blank">making API requests</a> to LTIAAS.
                {deploymentMethod === "firebase" &&(
                  <>
                    &nbsp;Store it in a safe place. We will never show it to you again. If you loose it, you will need to generate a new one.
                  </>
                )}
                </p>
                <div className="code-box">
                  <code>{apiKey}</code>
                  <CopyToClipboard text={apiKey} onCopy={onCopyText}>
                    <div className="copy-area">
                      <button className="btn btn-info mr-1">
                        <i className="far fa-copy"></i>
                      </button>
                      {isCopied ? (
                        <span style={{ color: "var(--danger)" }}>Copied.</span>
                      ) : null}
                    </div>
                  </CopyToClipboard>
                </div>
              </>
            }
            {apiKey === "" && settingsCommitted && deploymentMethod === "firebase" && 
              <>
                <p>
                  LTIAAS does not display your API Key after it is initially created.
                  If you need a new API Key at any time, please click the button below to generate a new one.
                </p>
                <div>
                  <Button
                    className={"btn mr-2 btn-info"}
                    onClick={()=>{setShowDialog(true)}}
                    disabled={(inRotate?'disabled':'')}
                    variant="warning"
                  >
                    {inRotate && <Loader /> }
                    {'Generate New API Key'}
                  </Button>
                </div>
              </>
            }
          </div>
        </div>
        <Modal
          show={showDialog}
          onHide={() => {
            setShowDialog(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Generate New API Key</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to generate a new API Key? If you do this, your old API Key will no longer work.
          </Modal.Body>
          <Modal.Footer>
            <button 
              type="button"
              className="btn btn-danger"
              onClick={e => {
                e.preventDefault();
                rotateApiKey();
              }}
            >
              Generate New API Key
            </button>
            <button 
              type="button"
              className="btn btn-secondary"
              onClick={e => {
                e.preventDefault();
                setShowDialog(false);
              }}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
        </>
      }
    </>
  )
}

export default ApiKey;