import React, {useState} from "react";
import { Button, Table } from "react-bootstrap";
import { Input } from "../../../components/Form";
import AlertPreformatted from "../../../components/AlertPreformatted";
import { RenameableHeader } from "./RenameableHeader";

export const UserAccountTable = ({accounts, getAccounts}) => {
    
    const [searchName, setSearchName] = useState({
        hasError: false,
        error: null,
        value: "",
    });
    const [searchId, setSearchId] = useState({
        hasError: false,
        error: null,
        value: "",
    });

    const [sort, setSort] = useState(true);

    const sortedAccounts = sort ? accounts.toSorted((a, b) => a.name.localeCompare(b.name)) : accounts.toSorted((a, b) => b.name.localeCompare(a.name));

    const filteredAccounts = sortedAccounts.filter((item) => {
        if(searchName.value.length > 1) {
            return item.name.toLowerCase().includes(searchName.value.toLowerCase())
        } else if(searchId.value.length > 1) {
            return item.id.toLowerCase().includes(searchId.value.toLowerCase())
        } else {
            return true
        }
    });
    const [errorMessage, setErrorMessage] = useState(null);

    return (
        <>
            {errorMessage !== null && (
                <AlertPreformatted
                    type="warning"
                    message={errorMessage}
                    dismissible={true}
                    onDismiss={() => setErrorMessage(null)}
                ></AlertPreformatted>
            )}
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                    <th>Account Name <Button size="sm" onClick={() => setSort(!sort)}>Sort</Button> </th>
                    <th>Account Id</th>
                    <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <Input
                                type="text"
                                value={searchName.value}
                                placeholder="Search..."
                                name="searchName"
                                required={false}
                                changeHandler={setSearchName}>
                            </Input>
                        </td>
                        <td>
                            <Input
                                type="text"
                                value={searchId.value}
                                placeholder="Search..."
                                name="searchId"
                                required={false}
                                changeHandler={setSearchId}>
                            </Input>
                        </td>
                        <td></td>
                    </tr>
                    {filteredAccounts.map((account, i) => 
                        <tr key={account.id}>
                        <td><RenameableHeader account={account} getAccounts={getAccounts}/>{account.type === "consumer" && (<i> (consumer)</i>)}{account.features?.trial && (<i> (trial)</i>)}</td>
                        <td>{account.id}</td>
                        <td>
                            {account.features?.trial &&
                                <Button style={{float:"right"}} href={'/account/'+account.id+'/billing/plan'} variant="success">
                                    <i className="fas fa-arrow-circle-up"></i> Upgrade
                                </Button>
                            }
                            <Button href={'/account/'+account.id+'/'} variant="primary">Account Overview</Button>
                        </td>
                        </tr>
                    )}
                </tbody>
            </Table>

        </>

    )
}
