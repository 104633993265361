
//a react component that allow the user to copy a value to their clipboard
import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

const CopyableElement = ({ value }) => {

    const [isCopied, setIsCopied] = useState(false);

    const onCopyText = () => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
    };

    return (
        <>
            <code>{value}</code>
            <CopyToClipboard text={value} onCopy={onCopyText}>
                <div className="copy-area">
                    <button className="btn btn-info mr-1">
                    <i className="far fa-copy"></i>
                    </button>
                    {isCopied ? (
                    <span style={{ color: "var(--danger)" }}>Copied.</span>
                    ) : null}
                </div>
            </CopyToClipboard>
        </>
    )
}

export default CopyableElement;
