import React, {useState, useContext, useEffect} from "react";
import { CloudFunctions } from "../../../../components/FirebaseAuth/firebase";
import { BreadcrumbContext } from '../../../../components/Breadcrumb';
import { Form, Field, Input } from '../../../../components/Form';
import { Navigate } from 'react-router-dom';
import Alert from "../../../../components/Alert";

const NewAccount = () => {
    const title = 'Create New Account';

    const [accountName, setAccountName] = useState({
        hasError: false,
        error: null,
        value: null
    });

    const [accountType, setAccountType] = useState({
        hasError: false,
        error: null,
        value: "provider"
    });

    const [errorMessage, setErrorMessage] = useState(null);

    const [inSubmit, setInSubmit] = useState(false);

    const [redirect, setRedirect] = useState(null);
    const { setBreadcrumb } = useContext(BreadcrumbContext);

    useEffect(() => {
        setBreadcrumb([
            {
                to: "/",
                text: "Home",
                active: false
            },
            {
                to: null,
                text: title,
                active: true
            }
        ]);
    }, [setBreadcrumb, title]);


    return (
        <>
            {redirect === null && 
            <>
                <div className="container-fluid">
                    <div className="animated fadeIn">
                        <div className="card">
                            <div className="card-header">
                                {title}
                            </div>
                            {errorMessage !== null && 
                                <Alert type="danger" message={errorMessage} dismissible={true} onDismiss={() => setErrorMessage(null)}></Alert>
                            }
                            <div className="card-body">
                                <Form handleSubmit={e =>{
                                    e.preventDefault();
                                    setInSubmit(true);
                                    setErrorMessage(null);
                                    const createAccount = CloudFunctions.httpsCallable('oncall');
                                    createAccount({
                                        name: "createAccount",
                                        data: {
                                            accountName: accountName.value,
                                            accountType: accountType.value
                                        }
                                    }).then(response => {
                                        const accountId = response.data.accountId;
                                        setRedirect('/account/'+accountId+'/billing/plan');
                                    }).catch(err => {
                                        setErrorMessage(err.message);
                                        setInSubmit(false);
                                    })
                                }}
                                disabled={accountName.hasError || accountName.value===null || accountType.hasError || accountType.value===null || inSubmit}
                                inSubmit={inSubmit}
                                enableDefaultButtons={true}>
                                    {false && 
                                    <Field label="Account Type">
                                        <p>
                                            Choose <b>LTI Tool</b> if you are developing a learning tool that plans to integrate into learning management systems (LMS).
                                            Choose <b>LTI Platform</b> if you plan to develop an LMS or other service that wants to integrate with LTI tools.
                                        </p>
                                        <div className="toggle">
                                            <Input type="radio" name="account-type" value="provider" required={true} changeHandler={setAccountType} id="accountTypeProvider" checked={accountType.value==="provider"} />
                                            <label htmlFor="accountTypeProvider">LTI Tool</label>
                                            <Input type="radio" name="account-type" value="consumer" required={true} changeHandler={setAccountType} id="accountTypeConsumer" checked={accountType.value==="consumer"} />
                                            <label htmlFor="accountTypeConsumer">LTI Platform</label>
                                        </div>
                                    </Field> }
                                    <Field label="Account Name">
                                        <p>Give your account a name of your choosing. This name is only used by you and helps differentiate your API services if you have multiple.</p>
                                        <Input type="text" name="account-name" maxLen={100} required={true} changeHandler={setAccountName} />
                                    </Field>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            }
            {redirect !== null &&
                <Navigate to={redirect}></Navigate>
            }
        </>

    )
}

export default NewAccount;