import React, { useContext, useEffect } from "react";
import { BreadcrumbContext } from '../../../../components/Breadcrumb';
import { AuthContext } from "../../../../components/FirebaseAuth";
import { Link } from "react-router-dom";

const PastDue = () => {

    const { userData } = useContext(AuthContext);
    const { setBreadcrumb } = useContext(BreadcrumbContext);

    useEffect(() => {
        setBreadcrumb([
            {
                to: "/",
                text: "Home",
                active: false
            },
            {
                to: "/account/"+userData.currentAccount.id+"/",
                text: userData.currentAccount.name + ` (${userData.currentAccount.id})`,
                active: false
            },
            {
                to: null,
                text: "Bill Past Due",
                active: true
            }
        ]);
    }, [userData, setBreadcrumb]);

    return (
        <>
            <div className="container-fluid">
                <div className="animated fadeIn">
                    <div className="alert alert-danger" role="alert">
                        <h4 className="alert-heading">Bill Past Due</h4>
                        <hr/>
                        <p>Your bill has not been paid and is past due. Your service is still running but your account is locked. To view your most recent invoice, please go to the <b><Link to={`/account/${userData.currentAccount.id}/billing`}>Billing Page</Link></b></p>
                        <p className="mb-0">If your bill is not paid within 30 days of the due date, your account may be shut down. Please contact <i><a target="blank" href="mailto:support@ltiaas.com">support@ltiaas.com</a></i> if you need help resolving this issue.</p>
                    </div>
                </div>
            </div>
        </>

    )
}

export default PastDue;