import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import PublicTemplate from "../../../../components/templates/PublicTemplate";
import Loader from "../../../../components/Loader";
import { BreadcrumbContext } from "../../../../components/Breadcrumb";
import { AuthContext } from "../../../../components/FirebaseAuth";
import { CloudFunctions } from "../../../../components/FirebaseAuth/firebase";
import { FirebaseAuth } from "../../../../components/FirebaseAuth/firebase";
import Alert from "../../../../components/Alert";
import Modal from 'react-bootstrap/Modal'
import EditRegistration from "../EditRegistration";
import EditRegistrationConsumer from "../EditRegistrationConsumer";
import Highlight from "react-highlight";
import CopyableElement from "../../../../components/CopyableElement";
import DataTable, { defaultThemes } from 'react-data-table-component';
import { Button } from "react-bootstrap";

const Registrations = () => {
  const title = "Registrations";

  const { userData } = useContext(AuthContext);
  const { setBreadcrumb } = useContext(BreadcrumbContext);

  const [settingsComplete, setSettingsComplete] = useState(null);
  const [subdomain, setSubdomain] = useState(null);
  const [registrations, setRegistrations] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [helpMessage, setHelpMessage] = useState(true);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [accountType, setAccountType] = useState("provider");
  const [hasDynamicRegistration, setHasDynamicRegistration] = useState(false);
  const [accountFirebase, setAccountFirebase] = useState(true);
  const [showChangeActivationDialog, setShowChangeActivationDialog] = useState({
    visible: false,
    row: {},
  });
  const [deleteDialog, setDeleteDialog] = useState({
    visible: false,
    row: {},
  });

  const [registrationTitle, setRegistrationTitle] = useState("Register Platform");
  const [registrationFormVisible, setRegistrationFormVisible] = useState(false);
  const [registrationURLsVisible, setRegistrationURLsVisible] = useState(false);
  const [registrationInfo, setRegistrationInfo] = useState(null);
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null)

  //TODO: Add filtering https://react-data-table-component.netlify.app/?path=/story/examples-filtering--filtering

  function ActiveSelect(data) {
    return <Button disabled={saving} variant={data.row.active ? "success" : "danger"} onClick={() => setShowChangeActivationDialog({visible: true, row: data.row})}>{data.row.active ? "true" : "false"}</Button>
  }

  function EditButton(data) {
    return <Button
      onClick={() => {
        setDeleteDialog({visible: true, row: data.row});
        const info = JSON.stringify(data.row, null, 2);
        //console.log(info);
        const replaced = info.replaceAll('\\n', '\n');
        setRegistrationInfo(replaced)
      }}
    ><i className="fa fa-edit"></i></Button>
    
  }

  const columns = [
    {
      name: 'Edit',
      button: true,
      cell: row => <EditButton row={row} />,
      sortable: true,
    },
    {
      name: 'id',
      selector: row => row.id,
      sortable: true,
      grow: 9
    },
    {
      name: 'clientId',
      selector: row => row.clientId,
      sortable: true,
      grow: 9
    },
    {
      name: 'Name',
      selector: row => row.name,
      sortable: true,
      grow: 9
    },
    {
      name: 'Active',
      selector: row => row.active,
      cell: row => <ActiveSelect row={row} />,
      sortable: true,
    },
    {
      name: 'URL',
      selector: row => (accountType === "consumer" ? row.launchEndpoint : row.url),
      sortable: true,
      grow: 9
    }
  ];

  const customStyles = {
    header: {
      style: {
        minHeight: '56px',
      },
    },
    headRow: {
      style: {
        borderTopStyle: 'solid',
        borderTopWidth: '1px',
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRightColor: defaultThemes.default.divider.default,
        '&:first-of-type': {
          borderLeftStyle: 'solid',
          borderLeftWidth: '1px',
          borderLeftColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRightColor: defaultThemes.default.divider.default,
        '&:first-of-type': {
          borderLeftStyle: 'solid',
          borderLeftWidth: '1px',
          borderLeftColor: defaultThemes.default.divider.default,
        },
      },
      
    },
  };

  const changeActivation = async (row) => {
    setSaving(true);
    try {
      const onCall = CloudFunctions.httpsCallable('oncall');
      let functionName = "";
      if(accountType === "consumer") {
        functionName = "updateToolRegistration";
      } else {
        functionName = "updateRegistration";
      }
      await onCall({
        name: functionName,
        data: {
          accountId: userData.currentAccount.id,
          id: row.id,
          activate: row.active ? "deactivate" : "activate"
        }
      });
    } catch (err) {
      setErrorMessage(err.message);
    }
    setSaving(false);
    fetchSettings(userData.currentAccount.id);
  }

  const deleteRegistration = async () => {
    setSaving(true);
    setDeleteDialog({visible: false, row: deleteDialog.row});
    setShowDeleteButton(false);
    try {
      let res = undefined;
      const deleteRegistration = CloudFunctions.httpsCallable('oncall');
      if(accountType === "consumer") {
        res = await deleteRegistration({
          name: "deleteToolRegistration",
          data: {
            accountId: userData.currentAccount.id,
            toolId: deleteDialog.row.id
          }
        });
      } else {
        res = await deleteRegistration({
          name: "deletePlatformRegistration",
          data: {
            accountId: userData.currentAccount.id,
            platformId: deleteDialog.row.id
          }
        });
      }
      if(res.data.hasOwnProperty('error')) {
        setErrorMessage(JSON.stringify(res.data, null, 2));
      } else {
        setSuccessMessage(`Id ${deleteDialog.row.id} deleted successfully`);
      }
    } catch (err) {
      setErrorMessage(err.message);
    }
    setDeleteDialog({visible: false, row: {}});
    setSaving(false);
    fetchSettings(userData.currentAccount.id);
  }

  const fetchSettings = async (accountId) => {
    //setSettingsComplete(false);
    let account_type = accountType;
    const accountDoc = await FirebaseAuth.firestore().collection("accounts").doc(accountId).get();
    if (accountDoc.exists) {
      const account_doc = accountDoc.data();
      account_type = account_doc.type;
      setAccountType(account_type)
      setAccountFirebase(account_doc.firebase)
      if (account_doc.hasOwnProperty("plans")) {
        const planPromises = account_doc.plans.map((plan) => {
          return plan.get();
        });
        const plans = await Promise.all(planPromises);
        plans.forEach((plan) => {
          if(plan.data().name.includes("Dynamic Registration")) {
            setHasDynamicRegistration(true);
          }
        });
      }
    }
    const doc = await FirebaseAuth.firestore().collection("subdomains").doc(accountId).get();
    if (doc.exists) {
      const api_settings = doc.data();
      if (api_settings.hasOwnProperty("subdomainName")) {
        // subdomain exists
        setSubdomain(api_settings.subdomainName);

        const getRegistrations = CloudFunctions.httpsCallable('oncall');
        let functionName = "";
        if(account_type === "consumer") {
          functionName = "getToolRegistrations";
        } else {
          functionName = "getRegistrations";
        }
        getRegistrations({
          name: functionName,
          data: {
            accountId: userData.currentAccount.id
          }
        }).then(res => {
          if(res.data.platforms) {
            setRegistrations(res.data.platforms);
          } else {
            setRegistrations(res.data);
          }
          setSettingsComplete(true);
          setLoading(false);
        }).catch(err => {
          setErrorMessage(err.message);
          setRegistrations(null);
          setSettingsComplete(true);
          setLoading(false);
        });
      } else {
        setRegistrations({});
        setSettingsComplete(false);
        setLoading(false);
      }
    } else {
      setRegistrations({});
      setSettingsComplete(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSettings(userData.currentAccount.id);
    setBreadcrumb([
      {
        to: "/",
        text: "Home",
        active: false,
      },
      {
        to: "/account/" + userData.currentAccount.id + "/",
        text: userData.currentAccount.name + ` (${userData.currentAccount.id})`,
        active: false,
      },
      {
        to: null,
        text: title,
        active: true,
      },
    ]);
  }, [userData, setBreadcrumb, title]);

  return (
    <>
    {loading ? (
      <PublicTemplate>
          <Loader size="5x" text="Loading..."/>
      </PublicTemplate>
    ):(
    <>
      <Modal
        show={registrationURLsVisible}
        onHide={() => {
          setRegistrationURLsVisible(false);
        }}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton>
          <Modal.Title>Registration URLs</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {accountType === "provider" &&
            <>
              <h3>How To Register</h3>
              <p>Use the URLs below to start the registration process with the LMS. Our <a style={{textDecoration: "underline"}} target="blank" href="https://ltiaas.com/guides/lms/introduction">LMS Guides</a> can help you understand how to start this process for all of the major LMSes.</p>
              {hasDynamicRegistration && 
                <>
                  <h4>Dynamic Registration</h4>
                  <p>
                    Use this URL for dynamic registration if the LMS supports it.
                  </p>
                  <table className="table">
                    <tr>
                      <th>Name</th>
                      <th>Value</th>
                    </tr>
                    <tr>
                      <td>Dynamic Registration URL</td>
                      <td><CopyableElement value={`https://${subdomain}/`+(accountFirebase ? `lti/register` : "register")} /> </td>
                    </tr>
                  </table>
                </>
              }
              <h4>Manual Registration</h4>
              <p>
                {hasDynamicRegistration ? "Otherwise, send " : "Send "} these URLs to the LMS administrator when they are installing your tool with a manual installation method.
              </p>
              <table className="table">
                <tr>
                  <th>Name</th>
                  <th>Value</th>
                </tr>
                <tr>
                  <td>URL (or Redirection URI(s))</td>
                  <td><CopyableElement value={`https://${subdomain}/`+(accountFirebase ? `lti/launch` : "")} /> </td>
                </tr>
                <tr>
                  <td>Target Link URI</td>
                  <td><CopyableElement value={`https://${subdomain}/`+(accountFirebase ? `lti/launch` : "")} /> </td>
                </tr>
                <tr>
                  <td>Initiate login URL (or OpenID Connect Initiation Url)</td>
                  <td><CopyableElement value={`https://${subdomain}/`+(accountFirebase ? `lti/login` : "login")} /></td>
                </tr>
                <tr>
                  <td>Authentication Method</td>
                  <td>JWK Set (or Public JWK URL)</td>
                </tr>
                <tr>
                  <td>Public Keyset (or Public JWK URL)</td>
                  <td><CopyableElement value={`https://${subdomain}/`+(accountFirebase ? `lti/keys` : "keys")} /></td>
                </tr>
              </table>
            </>
          }
          {accountType === "consumer" &&
            <>
              <p>
                Send these URLs to the LTI Tool owner when they are installing their tool into your Consumer.
              </p>
              <table className="table">
                <tr>
                  <th>Name</th>
                  <th>Value</th>
                </tr>
                <tr>
                  <td>URL</td>
                  <td><CopyableElement value={`https://${subdomain}`} /></td>
                </tr>
                <tr>
                  <td>Authentication Endpoint</td>
                  <td><CopyableElement value={`https://${subdomain}/lti/authenticate`} /> </td>
                </tr>
                <tr>
                  <td>Accesstoken Endpoint</td>
                  <td><CopyableElement value={`https://${subdomain}/lti/authorize`} /></td>
                </tr>
                <tr>
                  <td>Authorization Server</td>
                  <td><CopyableElement value={`https://${subdomain}/lti/authorize`} /></td>
                </tr>
                <tr>
                  <td>Authentication Method</td>
                  <td>JWK Set (or Public JWK URL)</td>
                </tr>
                <tr>
                  <td>Public Keyset (or Public JWK URL)</td>
                  <td><CopyableElement value={`https://${subdomain}/lti/keys`} /></td>
                </tr>
              </table>
            </>
          }
        </Modal.Body>
      </Modal>
      <Modal
        show={registrationFormVisible}
        onHide={() => {
          fetchSettings(userData.currentAccount.id);
          setRegistrationFormVisible(false);
        }}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton>
          <Modal.Title>{registrationTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {accountType === "provider" ?
            <EditRegistration data={deleteDialog.row}></EditRegistration>
            :
            <EditRegistrationConsumer data={deleteDialog.row}></EditRegistrationConsumer>
          }
        </Modal.Body>
      </Modal>
      <Modal
        show={deleteDialog.visible}
        onHide={() => {
          fetchSettings(userData.currentAccount.id);
          setDeleteDialog({visible: false, row: {}});
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Registration</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Choose an action below for this registration?
          <div>
              <Highlight className='language-json'>{registrationInfo}</Highlight>
          </div>
          {showDeleteButton && (
            <>
              <div>
                <b>Are you sure you want to delete?</b>
              </div>
              <button 
                type="button"
                className="btn btn-danger"
                onClick={e => {
                  e.preventDefault();
                  deleteRegistration();
                }}
              >
                Confirm Delete
              </button>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button 
            type="button"
            className="btn btn-danger"
            onClick={e => {
              e.preventDefault();
              setShowDeleteButton(!showDeleteButton);
            }}
          >
            Delete
          </button>
          <button 
            type="button"
            className="btn btn-secondary"
            onClick={e => {
              e.preventDefault();
              setDeleteDialog({visible: false, row: {}});
            }}
          >
            Cancel
          </button>
          <button 
            type="button"
            className="btn btn-info"
            onClick={e => {
              e.preventDefault();
              setRegistrationTitle(`Editing Registration: ${deleteDialog.row.id}`)
              setDeleteDialog({visible: false, row: deleteDialog.row});
              setRegistrationFormVisible(true);
              setShowDeleteButton(false);
            }}
          >
            Edit
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showChangeActivationDialog.visible}
        onHide={() => {
          setShowChangeActivationDialog({visible: false, row: {}});
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Activation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to {showChangeActivationDialog.row.active === "true" ? "DEACTIVATE" : "ACTIVATE"} the '{showChangeActivationDialog.row.name}' registration?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant={showChangeActivationDialog.row.active ? "danger" : "success"}
            onClick={e => {
              e.preventDefault();
              changeActivation(showChangeActivationDialog.row);
              setShowChangeActivationDialog({visible: false, row: {}});
            }}
          >
            {showChangeActivationDialog.row.active ? "Deactivate" : "Activate"}
          </Button>
          <button 
            type="button"
            className="btn btn-secondary"
            onClick={e => {
              e.preventDefault();
              setShowChangeActivationDialog({visible: false, row: {}});
            }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
      <div className="container-fluid">
        <div className="animated fadeIn">
          {errorMessage !== null && (
              <Alert
                  type="danger"
                  message={errorMessage}
                  dismissible={true}
                  onDismiss={() => setErrorMessage(null)}
              ></Alert>
              )}
          {saving && (
              <Alert
                  type="warning"
                  message="Saving..."
                  dismissible={false}
              ></Alert>
              )}
          {helpMessage !== null && (
              <Alert
                  type="info"
                  message="This page displays the current registrations that your API has. Once a registration has been completed (either manually or through dynamic registration) you will see it here. The true/false buttons in the 'Active' column can be used to activate/deactivate any registration."
                  dismissible={true}
                  onDismiss={() => setHelpMessage(null)}
              ></Alert>
              )}
          {successMessage !== null && (
              <Alert
                  type="info"
                  message={successMessage}
                  dismissible={true}
                  onDismiss={() => setSuccessMessage(null)}
              ></Alert>
              )}
          {!settingsComplete && registrations && (
            <div className="card">
              <div className="card-header">Finish your API setup</div>
              <div className="card-body">
                <p>
                  Before you API can go live, you need to set your API settings.
                  Please edit your &nbsp;
                  <b><Link
                    to={"/account/" + userData.currentAccount.id + "/settings"}
                  >
                    API Settings
                  </Link></b>
                  .
                </p>
              </div>
            </div>
          )}
          
          <div className="card">
            <div className="card-header">
              <h3 style={{float:"left"}}>{title}</h3>
              <button 
                style={{float:"right"}}
                type="button"
                className="btn btn-success"
                onClick={e => {
                  e.preventDefault();
                  if(accountType === "consumer") {
                    setRegistrationTitle('Register Tool');
                  } else{
                    setRegistrationTitle('Register Platform');
                  }
                  setDeleteDialog({visible: false, row: {}});
                  setRegistrationFormVisible(true);
                }}
              >+ Add Registration</button>
              { subdomain !== null &&
                <button 
                  style={{marginRight:"1rem", float: "right"}}
                  type="button"
                  className="btn btn-primary"
                  onClick={e => {
                    e.preventDefault();
                    setRegistrationURLsVisible(true);
                  }}
                >Display Registration URLs</button>
              }
            </div>
            <div className="card-body">
              {!userData.currentAccount.subscriptionStatus && (
                <p>
                  Account status is not active,{" "}
                  <b><Link to={"/account/" + userData.currentAccount.id + "/plan"}>
                    activate a plan here to continue
                  </Link></b>
                  .
                </p>
              )}
              {registrations && 
                <DataTable
                  data={registrations}
                  columns={columns}
                  pagination
                  customStyles={customStyles}
                />
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )}
  </>
  );
};

export default Registrations;
