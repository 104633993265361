import React, { useState, useContext, useEffect } from "react";
import { BreadcrumbContext } from "../../../../components/Breadcrumb";
import { Form, Field, Input, Select } from "../../../../components/Form";
import { AuthContext } from "../../../../components/FirebaseAuth";
import { CloudFunctions } from "../../../../components/FirebaseAuth/firebase";
import Alert from "../../../../components/Alert";
import Highlight from "react-highlight";
import PropTypes from "prop-types";

const EditRegistrationConsumer = (props) => {

  const { data } = props;

  const title = data.id ? `Editing Registration: ${data.id}` : "New Registration";
  const toolId = data.id;

  const [authConfigMethod, setAuthConfigMethod] = useState({
      hasError: false,
      error: null,
      value: data.authConfig ? data.authConfig.method : "JWK_SET"
  });
  const [authConfigKey, setAuthConfigKey] = useState({
    hasError: false,
    error: null,
    value: data.authConfig ? data.authConfig.key : ""
  });
  const [name, setName] = useState({
    hasError: false,
    error: null,
    value: data.name || ""
});

  // Consumer properties
  const [launchEndpoint, setLaunchEndpoint] = useState({
    hasError: false,
    error: null,
    value: data.launchEndpoint || ""
  });
  const [loginEndpoint, setLoginEndpoint] = useState({
    hasError: false,
    error: null,
    value: data.loginEndpoint || ""
  });
  const [deeplinkingEndpoint, setDeeplinkingEndpoint] = useState({
    hasError: false,
    error: null,
    value: data.deeplinkingEndpoint || ""
  });
  const [redirectionUris, setRedirectionUris] = useState({
    hasError: false,
    error: null,
    value: data.redirectionUris ? data.redirectionUris.join(",") : ""
  });
  const [permissions_MEMBERSHIPS_READ, setPermissions_MEMBERSHIPS_READ] = useState({
    hasError: false,
    error: null,
    value: data.permissions ? (data.permissions.includes("MEMBERSHIPS_READ") ? "true" : "false") : "false"
  });
  const [permissions_LINEITEMS_READ, setPermissions_LINEITEMS_READ] = useState({
    hasError: false,
    error: null,
    value: data.permissions ? (data.permissions.includes("LINEITEMS_READ") ? "true" : "false") : "false"
  });
  const [permissions_LINEITEMS_READ_WRITE, setPermissions_LINEITEMS_READ_WRITE] = useState({
    hasError: false,
    error: null,
    value: data.permissions ? (data.permissions.includes("LINEITEMS_READ_WRITE") ? "true" : "false") : "false"
  });
  const [permissions_GRADES_READ, setPermissions_GRADES_READ] = useState({
    hasError: false,
    error: null,
    value: data.permissions ? (data.permissions.includes("GRADES_READ") ? "true" : "false") : "false"
  });
  const [permissions_GRADES_WRITE, setPermissions_GRADES_WRITE] = useState({
    hasError: false,
    error: null,
    value: data.permissions ? (data.permissions.includes("GRADES_WRITE") ? "true" : "false") : "false"
  });
  const [personalData, setPersonalData] = useState(data.personalData || "NONE");
  const [customParameters, setCustomParameters] = useState({
    hasError: false,
    error: null,
    value: data.customParameters ? Object.keys(data.customParameters).map(key => `${key}=${data.customParameters[key]}`).join(",") : ""
  });

  // Form state
  const [inSubmit, setInSubmit] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [APIResponse, setAPIResponse] = useState(null);
  const { userData } = useContext(AuthContext);
  const { setBreadcrumb } = useContext(BreadcrumbContext);



  function validateForm() {
    return (
      launchEndpoint.value === "" ||
      loginEndpoint.value === "" ||
      name.value === "" ||
      redirectionUris.value === "" ||
      personalData === "" ||
      authConfigMethod.value === "" ||
      authConfigKey.value === "" ||
      inSubmit
    );
  }

  const sendRegistration = async () => {
    setInSubmit(true);

    const permissionsArray = []
    if(permissions_MEMBERSHIPS_READ.value === "true") {
        permissionsArray.push("MEMBERSHIPS_READ")
    }
    if(permissions_LINEITEMS_READ.value === "true") {
        permissionsArray.push("LINEITEMS_READ")
    }
    if(permissions_LINEITEMS_READ_WRITE.value === "true") {
        permissionsArray.push("LINEITEMS_READ_WRITE")
    }
    if(permissions_GRADES_READ.value === "true") {
        permissionsArray.push("GRADES_READ")
    }
    if(permissions_GRADES_WRITE.value === "true") {
        permissionsArray.push("GRADES_WRITE")
    }

    const params = customParameters.value.split(',');
    const customParametersMap = {};
    for (let i = 0; i < params.length; i++) {
      var parts = params[i].split('=');
      if(parts.length === 2) {
        customParametersMap[parts[0].trim()] = parts[1].trim();
      }
    }

    const data = {
      name: name.value,
      launchEndpoint: launchEndpoint.value,
      loginEndpoint: loginEndpoint.value,
      deeplinkingEndpoint: deeplinkingEndpoint.value,
      redirectionUris: redirectionUris.value.split(",").map((item) => {return item.trim()}),
      authConfig: {
        method: authConfigMethod.value,
        key: authConfigKey.value
      },
      permissions: permissionsArray,
      personalData: personalData,
      customParameters: customParametersMap
    };

    if(toolId) {
      const editToolRegistration = CloudFunctions.httpsCallable('oncall');
      editToolRegistration({
        name: "editToolRegistration",
        data: {
          accountId: userData.currentAccount.id,
          toolId: toolId,
          data: data
        }
      }).then(res => {
        setInSubmit(false);
        if(res.data.hasOwnProperty('error')) {
          setErrorMessage(`Tool registeration edit failed: ${res.data.details.message}`);
          setAPIResponse(JSON.stringify(res.data, null, 2));
        } else {
          setSuccessMessage(`Tool registeration edited successfully.`);
          setAPIResponse(JSON.stringify(res.data, null, 2));
        }
      }).catch(err => {
        setErrorMessage(err.message);
        setInSubmit(false);
      });
    } else {
      const registerTool = CloudFunctions.httpsCallable('oncall');
      registerTool({
        name: "registerTool",
        data: {
          accountId: userData.currentAccount.id,
          data: data
        }
      }).then(res => {
        setInSubmit(false);
        if(res.data.hasOwnProperty('error')) {
          setErrorMessage(`Tool registeration failed: ${res.data.details.message}`);
          setAPIResponse(JSON.stringify(res.data, null, 2));
        } else {
          setSuccessMessage(`Tool registered successfully.`);
          setAPIResponse(JSON.stringify(res.data, null, 2));
        }
      }).catch(err => {
        setErrorMessage(err.message);
        setInSubmit(false);
      });
    }
  }

  useEffect(() => {
    setBreadcrumb([
      {
        to: "/",
        text: "Home",
        active: false,
      },
      {
        to: "/account/" + userData.currentAccount.id + "/",
        text: userData.currentAccount.name + ` (${userData.currentAccount.id})`,
        active: false,
      },
      {
        to: null,
        text: title,
        active: true,
      },
    ]);
  }, [setBreadcrumb, title]);

  const validUrlRegex = "https?:\\/\\/((?!(localhost|(127\\.)|(192\\.168\\.)|(10\\.)|(172\\.1[6-9]\\.)|(172\\.2[0-9]\\.)|(172\\.3[0-1]\\.)|(::1$)|([fF][cCdD]))).)[a-zA-Z0-9\\.\\-\\_]+.+"

  return (
    <>
      <div className="container-fluid">
        <div className="animated fadeIn">
          {errorMessage !== null && (
            <Alert
                type="danger"
                message={errorMessage}
                dismissible={true}
                onDismiss={() => setErrorMessage(null)}
            ></Alert>
          )}
          {successMessage !== null && (
            <Alert
                type="success"
                message={successMessage}
                dismissible={true}
                onDismiss={() => setSuccessMessage(null)}
            ></Alert>
          )}
          {APIResponse !== null && (
            <div>
              <Highlight className='language-json'>{APIResponse}</Highlight>
            </div>
          )}
          {APIResponse === null && (
            <div className="card">
              <div className="card-body">
                <Form
                  handleSubmit={(e) => {
                    e.preventDefault();
                    setInSubmit(true);
                    setErrorMessage(null);
                    sendRegistration().catch((err) => {
                      setErrorMessage(err.message);
                      setInSubmit(false);
                    });
                  }}
                  disabled={validateForm()}
                  inSubmit={inSubmit}
                  enableDefaultButtons={true}
                >
                  <Field
                    label="name"
                    description="Tool name"
                  >
                    <Input
                      type="text"
                      value={name.value}
                      name="name"
                      required={true}
                      changeHandler={setName}
                    />
                  </Field>
                  <Field
                    label="Launch Endpoint"
                    description="Tool Launch endpoint URL"
                  >
                    <Input
                      type="text"
                      value={launchEndpoint.value}
                      name="Launch Endpoint"
                      required={false}
                      validRegex={validUrlRegex}
                      customErrorMessage={"All URLs must be HTTPS and accessible to our servers on the internet. Localhost is not allowed. Use a dynamic DNS service like NGROK to exposure your server to the internet."}
                      changeHandler={setLaunchEndpoint}
                    />
                  </Field>
                  <Field
                    label="Login Endpoint"
                    description="Tool Login endpoint URL"
                  >
                    <Input
                      type="text"
                      value={loginEndpoint.value}
                      name="Login Endpoint"
                      required={false}
                      validRegex={validUrlRegex}
                      customErrorMessage={"All URLs must be HTTPS and accessible to our servers on the internet. Localhost is not allowed. Use a dynamic DNS service like NGROK to exposure your server to the internet."}
                      changeHandler={setLoginEndpoint}
                    />
                  </Field>
                  <Field
                    label="Deep Linking Endpoint"
                    description="Tool deep linking endpoint URL"
                  >
                    <Input
                      type="text"
                      value={deeplinkingEndpoint.value}
                      name="Deep Linking Endpoint"
                      required={false}
                      validRegex={validUrlRegex}
                      customErrorMessage={"All URLs must be HTTPS and accessible to our servers on the internet. Localhost is not allowed. Use a dynamic DNS service like NGROK to exposure your server to the internet."}
                      changeHandler={setDeeplinkingEndpoint}
                    />
                  </Field>
                  <Field
                    label="Redirection URIs"
                    description="A comma-separated list of URLs that the tool is allowed to redirect to."
                  >
                    <Input
                      type="text"
                      value={redirectionUris.value}
                      name="Redirection URIs"
                      required={false}
                      placeholder="https://tool.com,https://tool.com/path"
                      changeHandler={setRedirectionUris}
                    />
                  </Field>
                  

                  <Field
                    label="AuthConfig Method"
                    description="Tool token authentication method"
                  >
                    <Select
                      value={authConfigMethod.value}
                      name="AuthConfig Method"
                      required={true}
                      options={[
                        {value: "JWK_SET", label: "JWK_SET"},
                        {value: "JWK_KEY", label: "JWK_KEY"},
                        {value: "RSA_KEY", label: "RSA_KEY"}
                      ]}
                      changeHandler={setAuthConfigMethod}
                    />
                  </Field>
                  <Field
                    label="AuthConfig Key"
                    description="Tool token authentication key"
                  >
                    <Input
                      type="text"
                      value={authConfigKey.value}
                      name="AuthConfig Key"
                      required={true}
                      changeHandler={setAuthConfigKey}
                    />
                  </Field>

                  <Field
                    label="Permissions"
                    description=""
                  >
                    <div className="toggle toggle-grouped">
                      <Input
                        type="radio"
                        name="permissions_GRADES_READ"
                        value="true"
                        required={true}
                        changeHandler={setPermissions_GRADES_READ}
                        id="permissions_GRADES_READTrue"
                        checked={permissions_GRADES_READ.value === "true"}
                      />
                      <label htmlFor="permissions_GRADES_READTrue">GRADES READ</label>
                      <Input
                        type="radio"
                        name="permissions_GRADES_READ"
                        value="false"
                        required={true}
                        changeHandler={setPermissions_GRADES_READ}
                        id="permissions_GRADES_READFalse"
                        checked={permissions_GRADES_READ.value !== "true"}
                      />
                      <label htmlFor="permissions_GRADES_READFalse">No</label>
                    </div>
                    <div className="toggle toggle-grouped">
                      <Input
                        type="radio"
                        name="permissions_GRADES_WRITE"
                        value="true"
                        required={true}
                        changeHandler={setPermissions_GRADES_WRITE}
                        id="permissions_GRADES_WRITETrue"
                        checked={permissions_GRADES_WRITE.value === "true"}
                      />
                      <label htmlFor="permissions_GRADES_WRITETrue">GRADES WRITE</label>
                      <Input
                        type="radio"
                        name="permissions_GRADES_WRITE"
                        value="false"
                        required={true}
                        changeHandler={setPermissions_GRADES_WRITE}
                        id="permissions_GRADES_WRITEFalse"
                        checked={permissions_GRADES_WRITE.value !== "true"}
                      />
                      <label htmlFor="permissions_GRADES_WRITEFalse">No</label>
                    </div>
                    <div className="toggle toggle-grouped">
                      <Input
                        type="radio"
                        name="permissions_LINEITEMS_READ"
                        value="true"
                        required={true}
                        changeHandler={setPermissions_LINEITEMS_READ}
                        id="permissions_LINEITEMS_READTrue"
                        checked={permissions_LINEITEMS_READ.value === "true"}
                      />
                      <label htmlFor="permissions_LINEITEMS_READTrue">LINEITEMS READ</label>
                      <Input
                        type="radio"
                        name="permissions_LINEITEMS_READ"
                        value="false"
                        required={true}
                        changeHandler={setPermissions_LINEITEMS_READ}
                        id="permissions_LINEITEMS_READFalse"
                        checked={permissions_LINEITEMS_READ.value !== "true"}
                      />
                      <label htmlFor="permissions_LINEITEMS_READFalse">No</label>
                    </div>
                    <div className="toggle toggle-grouped">
                      <Input
                        type="radio"
                        name="permissions_LINEITEMS_READ_WRITE"
                        value="true"
                        required={true}
                        changeHandler={setPermissions_LINEITEMS_READ_WRITE}
                        id="permissions_LINEITEMS_READ_WRITETrue"
                        checked={permissions_LINEITEMS_READ_WRITE.value === "true"}
                      />
                      <label htmlFor="permissions_LINEITEMS_READ_WRITETrue">LINEITEMS READ/WRITE</label>
                      <Input
                        type="radio"
                        name="permissions_LINEITEMS_READ_WRITE"
                        value="false"
                        required={true}
                        changeHandler={setPermissions_LINEITEMS_READ_WRITE}
                        id="permissions_LINEITEMS_READ_WRITEFalse"
                        checked={permissions_LINEITEMS_READ_WRITE.value !== "true"}
                      />
                      <label htmlFor="permissions_LINEITEMS_READ_WRITEFalse">No</label>
                    </div>
                    <div className="toggle toggle-grouped">
                      <Input
                        type="radio"
                        name="permissions_MEMBERSHIPS_READ"
                        value="true"
                        required={true}
                        changeHandler={setPermissions_MEMBERSHIPS_READ}
                        id="permissions_MEMBERSHIPS_READTrue"
                        checked={permissions_MEMBERSHIPS_READ.value === "true"}
                      />
                      <label htmlFor="permissions_MEMBERSHIPS_READTrue">MEMBERSHIPS READ</label>
                      <Input
                        type="radio"
                        name="permissions_MEMBERSHIPS_READ"
                        value="false"
                        required={true}
                        changeHandler={setPermissions_MEMBERSHIPS_READ}
                        id="permissions_MEMBERSHIPS_READFalse"
                        checked={permissions_MEMBERSHIPS_READ.value !== "true"}
                      />
                      <label htmlFor="permissions_MEMBERSHIPS_READFalse">No</label>
                    </div>
                  </Field>

                  <Field label="Personal Data" description="What personal data should LTIAAS allow to be passed to the tool?">
                    <select className="form-control col-md-6 col-sm-8" defaultValue={personalData} onChange={e => {
                        setPersonalData(e.target.value);
                    }}>
                      <option value="NONE">NONE</option>
                      <option value="EMAIL">EMAIL</option>
                      <option value="NAME">NAME</option>
                      <option value="COMPLETE">COMPLETE</option>
                    </select>
                  </Field>
                    
                  <Field
                    label="Custom Parameters"
                    description="List any custom key/value pairs that will be passed to the tool after launch"
                  >
                    <Input
                      type="text"
                      value={customParameters.value}
                      name="customParameters"
                      placeholder="key=value,key=value,..."
                      required={false}
                      changeHandler={setCustomParameters}
                    />
                  </Field>
                  
                  {errorMessage !== null && (
                      <Alert
                          type="danger"
                          message={errorMessage}
                          dismissible={true}
                          onDismiss={() => setErrorMessage(null)}
                      ></Alert>
                  )}
                </Form>
              </div> 
            </div>
          )}
        </div>
      </div>
    </>
  );
};

EditRegistrationConsumer.propTypes = {
  data: PropTypes.object
}

export default EditRegistrationConsumer;
