import React from "react";
import { Link } from "react-router-dom";

export const BreadcrumbContext = React.createContext();

export const Breadcrumb = ({links}) => {
    return (
            <ol className="mfe-auto breadcrumb border-0 m-0 px-0 px-md-3">
                {links !== null && links.map((link, key) => {
                        const txtArray = link.text.split("(");
                        let link_id = "";
                        let link_text = link.text;
                        if(txtArray.length > 1) {
                            link_id = "("+txtArray[txtArray.length - 1];
                            link_text = txtArray.slice(0, -1).join("(");
                        }
                        return (
                            <li key={key} className={link.active?"active breadcrumb-item":"breadcrumb-item"}>
                                {link.to !== null && 
                                    <Link to={link.to}>{link_text}<i style={{color: "#8a93a2"}}>{link_id}</i></Link>
                                }
                                {link.to === null && 
                                    <>{link.text}</>
                                }
                            </li>
                        )
                    })
                }
            </ol>
    )
}

export default Breadcrumb;