import React, { useState, useContext, useEffect } from "react";
import { BreadcrumbContext } from "../../../../components/Breadcrumb";
import { AuthContext } from "../../../../components/FirebaseAuth";
import { FirebaseAuth } from "../../../../components/FirebaseAuth/firebase";
import { CloudFunctions } from "../../../../components/FirebaseAuth/firebase";
import { Link } from "react-router-dom";
import Loader from "../../../../components/Loader";
import Plot from 'react-plotly.js';
import Placeholder from 'react-bootstrap/Placeholder';
import { Alert } from "react-bootstrap";

const Overview = () => {
  const title = "Overview";

  const { userData } = useContext(AuthContext);
  const { setBreadcrumb } = useContext(BreadcrumbContext);

  const [settingsComplete, setSettingsComplete] = useState(null);
  const [deployNeeded, setDeployNeeded] = useState(false);
  const [numRegistrations, setNumRegistrations] = useState(null);
  const [monthlyLaunchesPerformed, setMonthlyLaunchesPerformed] = useState(null);
  const [monthlyActiveUsers, setMonthlyActiveUsers] = useState(null);
  const [estimatedCost, setEstimatedCost] = useState(null);
  const [regions, setRegions] = useState([]);
  const [statusMessages, setStatusMessages] = useState([]);
  const [stats, setStats] = useState({});
  const [billingRenewDate, setBillingRenewDate] = useState(null);
  const [billingRenewDateOld, setBillingRenewDateOld] = useState(null);

  const fetchSettings = async (accountId) => {
    const doc = await FirebaseAuth.firestore().collection("subdomains").doc(accountId).get();
    if (doc.exists) {
      const api_settings = doc.data();
      if (api_settings.hasOwnProperty("subdomainName")) {
        // subdomain exists
        setSettingsComplete(true);
        const docs = await FirebaseAuth.firestore().collection("accounts").doc(accountId).collection("api_settings").doc("private").get();
        if (docs.exists) {
          const account_settings = docs.data();

          // get deployment status
          if (account_settings.hasOwnProperty("lastSave")) {
            if (account_settings.hasOwnProperty("lastDeploy")) {
                if(account_settings.lastSave.toDate() > account_settings.lastDeploy.toDate()) {
                    setDeployNeeded(true);
                } else {
                    setDeployNeeded(false);
                }
            } else {
                setDeployNeeded(true);
            }
          }

          // get registrations
          const getApiMetrics = CloudFunctions.httpsCallable('oncall');
          try{
            const res = await getApiMetrics({
              name: "getApiMetrics",
              data: {
                accountId: userData.currentAccount.id
              }
            });
            if('platforms' in res.data) {
              setNumRegistrations(res.data.platforms.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
            }
            if('price' in res.data) {
              setEstimatedCost(res.data.price / 100);
            } else {
              setEstimatedCost(0);
            }
            if('status' in res.data) {
              //console.log(res.data)
              setStatusMessages(res.data.status.messages || []);
              setRegions(res.data.status.regions);
            } else {
              setRegions([{
                "healthy": true,
                "name": "Self Hosted"
              }]);
            }
            if('stats' in res.data) {
              const stats = res.data.stats;
              stats.sort((a,b) => a.time - b.time);
              const mau = []
              const mlp = [];
              const time = [];
              stats.forEach(e => {
                mau.push(e.mau);
                mlp.push(e.mlp);
                time.push(e.time);
              });
              const mau_diffs = mau.slice(1).map((x,i)=> x>=mau[i] ? x-mau[i] : x);
              const mlp_diffs = mlp.slice(1).map((x,i)=> x>=mlp[i] ? x-mlp[i] : x);
              setStats({
                mau: mau_diffs,
                mlp: mlp_diffs,
                time: time.splice(1)
              });
              if(stats?.length > 0) {
                setMonthlyActiveUsers(stats[stats.length-1].mau.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
                setMonthlyLaunchesPerformed(stats[stats.length-1].mlp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
              }
            }
            if('monthlyActiveUsers' in res.data) {
              setMonthlyActiveUsers(res.data.monthlyActiveUsers);
            } else {
              setMonthlyActiveUsers(0);
            }
            if('monthlyActiveUsers' in res.data) {
              setMonthlyLaunchesPerformed(res.data.monthlyLaunchesPerformed);
            } else {
              setMonthlyLaunchesPerformed(0);
            }
          } catch (e) {
            //console.log(e)
          }
        }
      } else {
        setSettingsComplete(false);
      }
    }
  };

  useEffect(() => {
    fetchSettings(userData.currentAccount.id);
    const periodEnd = new Date(0);
    periodEnd.setUTCSeconds(userData.currentAccount.subscriptionCurrentPeriodEnd);
    periodEnd.setMonth(periodEnd.getMonth() - 1)
    setBillingRenewDate(periodEnd.getTime())
    periodEnd.setMonth(periodEnd.getMonth() - 1)
    setBillingRenewDateOld(periodEnd.getTime())
  }, [userData]);

  useEffect(() => {
    setBreadcrumb([
      {
        to: "/",
        text: "Home",
        active: false,
      },
      {
        to: "/account/" + userData.currentAccount.id + "/",
        text: userData.currentAccount.name + ` (${userData.currentAccount.id})`,
        active: false,
      },
      {
        to: null,
        text: title,
        active: true,
      },
    ]);
  }, [userData, setBreadcrumb, title]);

  return (
    <>
      <div className="container-fluid">
        <div className="animated fadeIn">
          {userData.currentAccount.subscriptionStatus && settingsComplete && statusMessages.length > 0 && (
            statusMessages.map((value, index) => {
              if(value.active) {
                return <Alert key={index} variant={value.severity}>{value.message}</Alert>
              }
              return <></>
            })
          )}
          {userData.currentAccount.subscriptionStatus && !settingsComplete && (
            <div className="card">
              <div className="card-header">Finish your API setup</div>
              <div className="card-body">
                <p>
                  Before you API can go live, you need to set your API settings.
                  Please edit your &nbsp;
                  <b><Link
                    to={"/account/" + userData.currentAccount.id + "/settings"}
                  >
                    API Settings
                  </Link></b>
                  .
                </p>
              </div>
            </div>
          )}
          {userData.currentAccount.subscriptionStatus && deployNeeded && userData.currentAccount.role === 'admin' && (
            <div className="card border-danger mb-3">
              <div className="card-header">Deploy Needed</div>
              <div className="card-body">
                <p>
                  Your API settings have been saved, but they have not been deployed.
                  Please go to the &nbsp;
                  <Link
                    to={"/account/" + userData.currentAccount.id + "/settings"}
                  >
                    API Settings
                  </Link>
                  &nbsp; page to deploy them.
                </p>
              </div>
            </div>
          )}
          {!userData.currentAccount.subscriptionStatus && (
          <div className="card">
            <div className="card-header">Account not active</div>
            <div className="card-body">
                <p>
                  Account status is not active,{" "}
                  <b><Link to={"/account/" + userData.currentAccount.id + "/plan"}>
                    activate a plan here to continue
                  </Link></b>
                  .
                </p>
            </div>
          </div>
         )}
        </div>
          <div className="row row-cols-auto d-flex">
            {userData.currentAccount.subscriptionStatus && numRegistrations != null && (
            <div className="col d-flex" style={{ minWidth: 300 + "px" }}>
              <div className="card text-white" style={{ flexGrow: 1, backgroundColor: "#013f71" }} >
                <div className="card-body">
                  <h5 className="card-title">Registrations</h5>
                  <h6 className="card-subtitle mb-2 text-muted"> </h6>
                  <h1 className="card-text">
                    <Link to={"/account/" + userData.currentAccount.id + "/registrations"} className="col d-flex" style={{ minWidth: 200 + "px", color:"#f2285d" }}>
                    {numRegistrations}
                    </Link>
                  </h1>
                </div>
              </div>
            </div>
            )}
            {userData.currentAccount.subscriptionStatus && settingsComplete && (
            <div className="col d-flex" style={{ minWidth: 250 + "px" }}>
              <div className="card text-white" style={{ flexGrow: 1, backgroundColor: "#013f71" }} >
                <div className="card-body">
                  <h5 className="card-title">Total Launches This Month</h5>
                  <h6 className="card-subtitle mb-2 text-muted"> </h6>
                  <h1 className="card-text">
                    {monthlyLaunchesPerformed !== null ? monthlyLaunchesPerformed : <Loader size="1x"/>}</h1>
                </div>
              </div>
            </div>
            )}
            {userData.currentAccount.subscriptionStatus && settingsComplete && (
            <div className="col d-flex" style={{ minWidth: 250 + "px" }}>
              <div className="card text-white" style={{ flexGrow: 1, backgroundColor: "#013f71" }} >
                <div className="card-body">
                  <h5 className="card-title">Monthly Active Users</h5>
                  <h6 className="card-subtitle mb-2 text-muted"> </h6>
                  <h1 className="card-text">{monthlyActiveUsers !== null ? monthlyActiveUsers : <Loader size="1x"/>}</h1>
                </div>
              </div>
            </div>
            )}
            {userData.currentAccount.subscriptionStatus && settingsComplete && (
            <div className="col d-flex" style={{ minWidth: 250 + "px" }}>
              <div className="card text-white" style={{ flexGrow: 1, backgroundColor: "#013f71" }} >
                <div className="card-body">
                  <h5 className="card-title">Next Bill: {(new Date(userData.currentAccount.subscriptionCurrentPeriodEnd * 1000)).toLocaleDateString()}</h5>
                  <h6 className="card-subtitle mb-2 text-muted"> </h6>
                  <h1 className="card-text">{estimatedCost !== null ? "$"+estimatedCost : <Loader size="1x"/>}</h1>
                </div>
              </div>
            </div>
            )}
            {userData.currentAccount.subscriptionStatus && settingsComplete && (
            <div className="col d-flex" style={{ maxWidth: 200 + "px", minWidth: 200 + "px" }}>
              <div className="card" style={{ flexGrow: 1}} >
                <div className="card-header">API Service Health</div>
                <div className="card-body">
                  {regions?.length > 0 ?
                    regions.map((r,i) => 
                    <div key={i}>
                      <i className={r.healthy ? "fa fa-check text-success" : "fa fa-exclamation-triangle text-warning"}></i>
                      {" "}{r.name}
                    </div>
                    ) : 
                    <Loader size="2x"></Loader>
                  }
                </div>
              </div>
            </div>
            )}
            {userData.currentAccount.subscriptionStatus && settingsComplete && userData.currentAccount.features?.trial && monthlyLaunchesPerformed !== null && (
            <div className="col d-flex" style={{ minWidth: 200 + "px" }}>
              <div className="card border-warning" style={{ flexGrow: 1}} >
                <div className="card-header">Trial Status</div>
                <div className="card-body">
                  <h6 className="card-subtitle mb-2 text-muted"> </h6>
                  <h4 className="card-text">You have <b>{500-monthlyLaunchesPerformed}</b> launches left this month.</h4>
                  <a className="btn btn-success" href={'/account/'+userData.currentAccount.id+'/billing/plan'}>Upgrade Now</a>
                </div>
              </div>
            </div>
            )}
            <div className="w-100"></div>
            {userData.currentAccount.subscriptionStatus && settingsComplete && (
            <div className="col d-flex">
              <div className="card" style={{ flexGrow: 1 }}>
                <div className="card-body p-1">
                {stats.time ? 
                  <Plot
                    data={[
                      {
                        x: stats.time,
                        y: stats.mlp,
                        type: 'scatter',
                        mode: 'lines',
                        marker: {color: '#023E73'},
                        name: 'Launches Performed'
                      },
                      {
                        x: stats.time,
                        y: stats.mau,
                        type: 'scatter',
                        mode: 'lines',
                        marker: {color: '#BF0436'},
                        name: 'New Users'
                      },
                    ]}
                    layout={{
                      title: 'Hourly Usage Statistics',
                      showlegend: true,
                      legend: {
                        borderwidth: 0,
                        x: 1,
                        xanchor: 'right',
                        y:1.1,
                        orientation: 'h' 
                      },
                      images: [{
                        name: 'watermark_1',
                        source: "/logo_large.png",
                        xref: "paper",
                        yref: "paper",
                        x: 0.05,
                        y: 0.9,
                        sizex: 0.3,
                        sizey: 0.3,
                        opacity: 0.1,
                        layer: "below"
                      }],
                      annotations: [
                        {
                          x: billingRenewDate,
                          y: 1,
                          xref: 'x',
                          yref: 'pixel',
                          text: 'Billing Restart',
                          showarrow: true,
                          arrowhead: 0,
                          opacity: 0.5,
                          ax: 0,
                          ay: -220
                        },
                        {
                          x: billingRenewDateOld,
                          y: 1,
                          xref: 'x',
                          yref: 'pixel',
                          text: 'Previous Billing Cycle',
                          showarrow: true,
                          arrowhead: 0,
                          opacity: 0.5,
                          ax: 0,
                          ay: -220
                        }
                      ],
                      xaxis: {
                        autorange: true,
                        rangeselector: {buttons: [
                            {
                              count: 1,
                              label: '1d',
                              step: 'day',
                              stepmode: 'backward'
                            },
                            {
                              count: 7,
                              label: '7d',
                              step: 'day',
                              stepmode: 'backward'
                            },
                            {
                              count: 1,
                              label: '1m',
                              step: 'month',
                              stepmode: 'backward'
                            },
                            {step: 'all'}
                          ]},
                        rangeslider: {autorange: true},
                        type: 'date'
                      },
                      yaxis: {
                        autorange: true,
                        type: 'linear'
                      },
                      margin: {
                        b:10,
                        l:60,
                        r:10,
                        t:60
                      }
                    }}
                    useResizeHandler={true}
                    style={{width: "100%", height: "450px"}}
                  />
                    :
                  <Placeholder animation="glow" style={{ width: "100%", height: 450 }} />
                }
                </div>
              </div>
            </div>
            )}
          </div>
      </div>
    </>
  );
};

export default Overview;
