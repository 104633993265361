import React, { useState, useEffect, useContext } from "react";
import Alert from "../../../../components/Alert";
import { BreadcrumbContext } from '../../../../components/Breadcrumb';
import { FirebaseAuth } from "../../../../components/FirebaseAuth/firebase";
import { Field, Form, Input } from "../../../../components/Form";
import Modal from 'react-bootstrap/Modal';
import firebase from "firebase/compat/app";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Buffer } from 'buffer';

const Secrets = () => {

    const createSecret = async () => {

        setInSubmit(true);
        //Encrypt message
        let key = await window.crypto.subtle.generateKey(
            {
              name: "AES-GCM",
              length: 256
            },
            true,
            ["encrypt", "decrypt"]
        );
        const enc = new TextEncoder();
        const encoded_message = enc.encode(message.value);
        const iv = await window.crypto.getRandomValues(new Uint8Array(12));
        const encrypted = await window.crypto.subtle.encrypt(
            { name: "AES-GCM", iv: iv },
            key,
            encoded_message
        );
        const createdAt = firebase.firestore.Timestamp.now().toDate()
        createdAt.setDate(createdAt.getDate() + 7);
        const exp = firebase.firestore.Timestamp.fromDate(createdAt);
        const iv_arr = [];
        iv.forEach((el) => {
            iv_arr.push(el.valueOf());
        })
        const base64_encrypted = Buffer.from(encrypted).toString('base64');
        const data = {
            data: base64_encrypted,
            iv: iv_arr,
            expires: exp
        }
        const docRef = FirebaseAuth.firestore().collection('secrets').doc();
        await docRef.set(data);
        setEncryptedDocId(docRef.id);
        const exported_key = await window.crypto.subtle.exportKey("raw", key);
        const exportedKeyBuffer = new Uint8Array(exported_key);
        const base64_key = Buffer.from(exportedKeyBuffer).toString('base64');
        setEncryptionKey(base64_key);
        setMessage({value: null});
        setInSubmit(false);
        setSecretFormVisible(false);
        setDecryptionKeyVisible(true);
    }

    const getSecret = async () => {
        setInSubmit(true);
        const docRef = FirebaseAuth.firestore().collection('secrets').doc(secretId.value);
        const secretDoc = await docRef.get();
        const doc_data = secretDoc.data();
        if(doc_data && doc_data.hasOwnProperty("data") && doc_data.hasOwnProperty("iv")) {
            const ciphertext = doc_data.data;
            const encodedBuffer = Buffer.from(ciphertext, 'base64');
            const rawKey = Buffer.from(decryptionKey.value, 'base64');
            //console.log(rawKey);
            const key = await window.crypto.subtle.importKey(
                "raw",
                rawKey,
                "AES-GCM",
                true,
                ["encrypt", "decrypt"]
            );

            const ivBuffer = new Uint8Array(12);
            const iv = doc_data.iv;
            for(let i=0; i< 12; i++) {
                ivBuffer[i] = iv[i];
            }
            //console.log(key)
            //console.log(encodedBuffer)
            try {
                const message = await window.crypto.subtle.decrypt({ name: "AES-GCM", iv: ivBuffer }, key, encodedBuffer);
                const dec = new TextDecoder();
                const decoded_message = dec.decode(message);
                setDecryptedMessage(decoded_message.toString());
                setDecryptedMessageVisible(true);
            } catch(e) {
                setErrorMessage("Message Decryption Failed");
            }
            await docRef.delete();
        } else {
            setErrorMessage("Message Not Found");
        }
        setInSubmit(false);
    }
    
    const [secretId, setSecretId] = useState({
        hasError: false,
        error: null,
        value: null
    });
    const [message, setMessage] = useState({
        hasError: false,
        error: null,
        value: null
    });
    const [decryptionKey, setDecryptionKey] = useState({
        hasError: false,
        error: null,
        value: null
    });
    const [secretFormVisible, setSecretFormVisible] = useState(false);
    const [decryptionKeyVisible, setDecryptionKeyVisible] = useState(false);
    const [decryptedMessageVisible, setDecryptedMessageVisible] = useState(false);
    const [inSubmit, setInSubmit] = useState(false);
    const [encryptedDocId, setEncryptedDocId] = useState(null);
    const [encryptionKey, setEncryptionKey] = useState(null);
    const [decryptedMessage, setDecryptedMessage] = useState(null);
    const [isEncryptedDocIdCopied, setIsEncryptedDocIdCopied] = useState(false);
    const [isEncryptionKeyCopied, setIsEncryptionKeyCopied] = useState(false);
    const [isDecryptedMessageCopied, setIsDecryptedMessageCopied] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const onCopyEncryptedDocId = () => {
        setIsEncryptedDocIdCopied(true);
        setTimeout(() => {
            setIsEncryptedDocIdCopied(false);
        }, 2000);
    };

    const onCopyEncryptionKey = () => {
        setIsEncryptionKeyCopied(true);
        setTimeout(() => {
            setIsEncryptionKeyCopied(false);
        }, 2000);
    };

    const onCopyDecryptedMessage = () => {
        setIsDecryptedMessageCopied(true);
        setTimeout(() => {
            setIsDecryptedMessageCopied(false);
        }, 2000);
    };

    // document snapshots

    const title = 'Secret Sharing'
    const { setBreadcrumb } = useContext(BreadcrumbContext);

    useEffect(() => {
        setBreadcrumb([
            {
                to: "/",
                text: "Home",
                active: false
            },
            {
                to: null,
                text: title,
                active: true
            }
        ]);
    },[setBreadcrumb]);

    return (
        <>
            <div className="container-fluid">
                <div className="animated fadeIn">
                    <div className="text-right mb-3">
                        <button className="btn btn-primary" onClick={() => setSecretFormVisible(true)}><i className="fa fa-plus"></i> Create Secret</button>
                    </div>
                    <Modal
                        show={secretFormVisible}
                        onHide={() => {
                           setSecretFormVisible(false); 
                        }}
                        backdrop="static"
                        keyboard={false}
                        dialogClassName="modal-90w"
                    >
                        <Modal.Header closeButton>
                        <Modal.Title>New Secret</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Alert type="info" message="Enter the information you want to encrypt in the box below. LTIAAS will encrypt it and give you a decryption key and an ID that can be used to retrieve the data only once." dismissible={false}></Alert>
                            <Form
                                handleSubmit={e => {
                                    e.preventDefault();
                                    createSecret();
                                }}
                                disabled={message.hasError || message.value===null || inSubmit}
                                submitBtnStyle="primary"
                                inSubmit={inSubmit}
                                enableDefaultButtons={true}
                            >
                                <Field label="Message">
                                    <Input type="textarea" rows="10" name="message" maxLen={10000} hasError={message.hasError} error={message.error} required={true} changeHandler={setMessage}/>
                                </Field>
                            </Form>
                        </Modal.Body>
                    </Modal>
                    <Modal
                        show={decryptionKeyVisible}
                        onHide={() => {
                           setDecryptionKeyVisible(false); 
                        }}
                        backdrop="static"
                        keyboard={false}
                        dialogClassName="modal-90w"
                    >
                        <Modal.Header closeButton>
                        <Modal.Title>Message Encrypted</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Alert type="info" message="Your message is stored encrypted on LTIAAS servers for 7 days. Once it is read it will be deleted. Copy the Secret ID and Decryption Key below and send them to the person that you want to read the message you just encrypted." dismissible={false}></Alert>
                            <b>Secret ID:</b> <code>{encryptedDocId}</code>
                            <CopyToClipboard text={encryptedDocId} onCopy={onCopyEncryptedDocId}>
                                <div className="copy-area">
                                <button className="btn btn-info mr-1">
                                    <i className="far fa-copy"></i>
                                </button>
                                {isEncryptedDocIdCopied ? (
                                    <span style={{ color: "var(--danger)" }}>Copied.</span>
                                ) : null}
                                </div>
                            </CopyToClipboard>
                            <br></br>
                            <br></br>
                            <b>Decryption Key:</b> <code>{encryptionKey}</code>
                            <CopyToClipboard text={encryptionKey} onCopy={onCopyEncryptionKey}>
                                <div className="copy-area">
                                <button className="btn btn-info mr-1">
                                    <i className="far fa-copy"></i>
                                </button>
                                {isEncryptionKeyCopied ? (
                                    <span style={{ color: "var(--danger)" }}>Copied.</span>
                                ) : null}
                                </div>
                            </CopyToClipboard>
                        </Modal.Body>
                    </Modal>
                    <Modal
                        show={decryptedMessageVisible}
                        onHide={() => {
                           setDecryptedMessageVisible(false);
                           setDecryptedMessage(null);
                        }}
                        backdrop="static"
                        keyboard={false}
                        dialogClassName="modal-90w"
                    >
                        <Modal.Header closeButton>
                        <Modal.Title>Decrypted Message</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Alert type="info" message="Here is your one-time message. It has been deleted from our servers." dismissible={false}></Alert>
                            <div className="code-box">
                                <pre className="code">{decryptedMessage}</pre>
                                <CopyToClipboard text={decryptedMessage} onCopy={onCopyDecryptedMessage}>
                                    <div className="copy-area">
                                    <button className="btn btn-info mr-1">
                                        <i className="far fa-copy"></i>
                                    </button>
                                    {isDecryptedMessageCopied ? (
                                        <span style={{ color: "var(--danger)" }}>Copied.</span>
                                    ) : null}
                                    </div>
                                </CopyToClipboard>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <div className="card">
                        <div className="card-header">
                            {title}
                        </div>
                        <div className="card-body">
                            <Alert type="info" message="Occasionally, it may become necessary for LTIAAS to share secrets with you such as API Keys or Private/Public Key pairs. This page allows you to create and retrieve encrypted self-destructing secret messages." dismissible={false}></Alert>
                            
                            <Form
                                handleSubmit={e => {
                                    e.preventDefault();
                                    getSecret();
                                }}
                                disabled={decryptionKey.hasError || decryptionKey.value === null || secretId.hasError || secretId.value===null || inSubmit}
                                submitBtnStyle="primary"
                                inSubmit={inSubmit}
                                enableDefaultButtons={true}
                            >
                                <Field label="Secret ID">
                                    <Input type="text" name="secret-id" placeholder="Secret ID" hasError={secretId.hasError} error={secretId.error} minLen={5} maxLen={500} required={true} changeHandler={setSecretId} />
                                </Field>
                                <Field label="Decryption Key">
                                    <Input type="text" name="decryption-key" placeholder="Decryption Key" hasError={decryptionKey.hasError} error={decryptionKey.error} minLen={5} maxLen={500} required={true} changeHandler={setDecryptionKey} />
                                </Field>
                                {errorMessage !== null && <Alert type="danger" message={errorMessage} dismissible={true} onDismiss={() => setErrorMessage(null)}></Alert>}
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </>
        
    )
}

export default Secrets;