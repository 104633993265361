import React from "react";
import { CloudFunctions } from "../FirebaseAuth/firebase";
import { AuthContext } from "../FirebaseAuth";
import Loader from '../Loader';
import CopyToClipboard from "react-copy-to-clipboard";
import Alert from "../Alert";

const ConsumerPublicKey = (props) => {

  const {
    enabled
  } = props;

  const [errorMessage, setErrorMessage] = React.useState(null);
  const [publicKey, setPublicKey] = React.useState("");
  const [inGetPublicKey, setGetPublicKey] = React.useState(false);
  const [isCopied, setIsCopied] = React.useState(false);
  const { userData } = React.useContext(AuthContext);

  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const getPublicKey = async () => {
    setGetPublicKey(true);
    const getConsumerPublicKey = CloudFunctions.httpsCallable('oncall');
    getConsumerPublicKey({
      name: "getConsumerPublicKey",
      data: {
        accountId: userData.currentAccount.id
      }
    }).then(res => {
      if(res.data.hasOwnProperty("error")) {
        setErrorMessage(`${res.data.error}, Error ID:${res.data.details.errorId} `);
        setGetPublicKey(false);
        return;
      }
      setPublicKey(res.data.publicKey);
      setGetPublicKey(false);
    }).catch(err => {
      setErrorMessage(err.message);
      setGetPublicKey(false);
    });
  }

  return (
    <>
      {enabled && 
        <div className="card">
          <div className="card-header">Public Key</div>
          <div className="card-body">
            {errorMessage !== null && (
              <Alert
                  type="danger"
                  message={errorMessage}
                  dismissible={true}
                  onDismiss={() => setErrorMessage(null)}
              ></Alert>
            )}
            {publicKey !== "" && publicKey !== null && 
              <>
                <p>
                  Use this Public KEY when validating JWTs sent from LTIAAS.
                </p>
                <div className="code-box">
                  <pre className="code">{publicKey}</pre>
                  <CopyToClipboard text={publicKey} onCopy={onCopyText}>
                    <div className="copy-area">
                      <button className="btn btn-info mr-1">
                        <i className="far fa-copy"></i>
                      </button>
                      {isCopied ? (
                        <span style={{ color: "var(--danger)" }}>Copied.</span>
                      ) : null}
                    </div>
                  </CopyToClipboard>
                </div>
              </>
            }
            {publicKey === "" && enabled && 
              <>
                <p>
                  LTIAAS does not display your Public Key after it is initially created.
                  Please click the button below to display your public Key.
                </p>
                <div>
                  <button className={"btn mr-2 btn-info"} onClick={getPublicKey} disabled={(inGetPublicKey?'disabled':'')}>
                    {inGetPublicKey && <Loader /> }
                    {'Display Public Key'}
                  </button>
                </div>
              </>
            }
          </div>
        </div>
      }
    </>
  )
}

export default ConsumerPublicKey;