const config = process.env.REACT_APP_ENV === 'development' ?
    {
        "stripeConfig": {
            "public_api_key":"pk_test_51IbYArDCbhflpMUyRANr6EkL4Ekry3tR2WDUxkaBeNDR15KTabCWX2us7z2mBYc4uBCaFMMnjWMeWN0EDMCJ4tSJ00na7gkWqG"
        }
    }
    :{
        "stripeConfig": {
            "public_api_key":"pk_live_51IbYArDCbhflpMUy594argsfHbiHNO22Ge3abZDZQe8oXqfP3G4awsryq4Ac0cFJAmba6dFaUI3zB3pbilxba5AQ00dyTrZe2j"
        }
    }

module.exports = config;