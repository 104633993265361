import React, { useContext, useEffect, useState } from "react";
import { BreadcrumbContext } from '../../../../components/Breadcrumb';
import { AuthContext } from "../../../../components/FirebaseAuth";
import { CloudFunctions } from "../../../../components/FirebaseAuth/firebase";
import Alert from "../../../../components/Alert";
import {Form, Field, Input} from "../../../../components/Form";
import 'react-quill/dist/quill.snow.css';

// Similar comp to the one described here:
// https://www.joshwcomeau.com/react/the-perils-of-rehydration/#abstractions
function BrowserOnly({ children, fallback, }) {
    return <>{children?.()}</>;
}

const modules = {
    toolbar: [
      [{ 'font': [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link']
      ['clean'],
    ],
  };
  
  const formats = [
    'font',
    'bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block',
    'list', 'bullet', 'indent',
    'link'
  ];

const PrioritySupport = () => {

    const { userData } = useContext(AuthContext);
    const { setBreadcrumb } = useContext(BreadcrumbContext);
    const [title, setTitle] = useState({
        hasError: false,
        error: null,
        value: null
    });
    const [message, setMessage] = useState("");
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [inSubmit, setInSubmit] = useState(false);

    useEffect(() => {
        setBreadcrumb([
            {
                to: "/",
                text: "Home",
                active: false
            },
            {
                to: "/account/"+userData.currentAccount.id+"/",
                text: userData.currentAccount.name + ` (${userData.currentAccount.id})`,
                active: false
            },
            {
                to: null,
                text: "Priority Support",
                active: true
            }
        ]);
    }, [userData, setBreadcrumb]);

    return (
        <>
            <div className="container-fluid">
                <div className="animated fadeIn">
                    <div className="card">
                        <div className="card-header">
                            Priority Support
                        </div>
                        <div className="card-body">
                            <p>
                                Send a message for Priority Support.
                            </p>
                            {success ? (
                                <Alert type="success" message="Your message was sent. Please expect a response via email in less than 24 hours." dismissible={false} onDismiss={() => setSuccess(false)}></Alert>
                            ):(
                                <>
                                    {error !== null && 
                                        <Alert type="danger" message={error} dismissible={true} onDismiss={() => setError(null)}></Alert>
                                    }
                                    
                                    <Form handleSubmit={e => {
                                        e.preventDefault();
                                        setError(null);
                                        setSuccess(false);
                                        setInSubmit(true);
                                        const sendPrioritySupportMessage = CloudFunctions.httpsCallable('oncall');
                                        sendPrioritySupportMessage({
                                            name: "sendPrioritySupportMessage",
                                            data: {
                                                accountId: userData.currentAccount.id,
                                                title: title.value,
                                                message: message
                                            }
                                        }).then(res => {
                                            setInSubmit(false);
                                            setSuccess(true);
                                        }).catch(err => {
                                            setInSubmit(false);
                                            setError(err.message);
                                        });
                                    }}
                                    disabled={title.hasError || title.value===null || message.length===0 || inSubmit}
                                    submitBtnStyle="primary"
                                    inSubmit={inSubmit}
                                    enableDefaultButtons={true}
                                    >
                                        <Field label="Title">
                                            <Input type="text" name="title" hasError={title.hasError} error={title.error} required={true} validRegex="^.*$" changeHandler={setTitle}/>
                                        </Field>
                                        <Field label="Message">
                                            <BrowserOnly>
                                                {() => {
                                                const ReactQuill = require('react-quill');
                                                return <ReactQuill
                                                    theme="snow"
                                                    name="message"
                                                    value={message}
                                                    onChange={setMessage}
                                                    modules={modules}
                                                    formats={formats}
                                                />
                                                }}
                                            </BrowserOnly>
                                        </Field>
                                    </Form>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default PrioritySupport;