import React from "react";

const AlertPreformatted = ({type, message, dismissible, onDismiss}) => {

    return (
        <div className={"alert fade show alert-"+type+(dismissible?" alert-dismissible":"")} style={{"position": "fixed", "bottom": 0, "zIndex": 1000}} role="alert">
            <pre>{message}</pre>
            {dismissible && 
                <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={onDismiss} >
                    <span aria-hidden="true">&times;</span>
                </button>
            }
        </div>
    )
}

export default AlertPreformatted;