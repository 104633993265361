import React from 'react';

const PageNotFound = ({ children }) => (
    <div className="text-center text-white">
        <img alt="LTIAAS Logo" width="100" src="/logo-light.png" />
        <p></p>
        <h1>Page not found</h1>
        <p>Oops, the page you requested is not found.</p>
        <a style={{color:"#fff", textDecoration:"underline"}}  href="/">Home</a>
    </div>
);

export default PageNotFound;