import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useServiceWorker } from './useServiceWorker';
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';

function RefreshButton() {
    // refresh page message
	const { waitingWorker, showReload, reloadPage } = useServiceWorker();
	React.useEffect(() => {
		if (showReload && waitingWorker) {
			const Msg = ({ closeToast, toastProps }) => (
				<div>
					<h4>New Version Available</h4>
					A new version of this page is available.{" "}
                    <Button variant="outline-primary" size="sm" onClick={() => {reloadPage(); closeToast();}}>Refresh Now</Button>
				</div>
			)
			toast.info(<Msg />, {
				position: "bottom-right",
				autoClose: false,
				closeOnClick: false,
				theme: "light",
				toastId: 1
			});
		} else {
            toast.dismiss(1);
        }
	}, [waitingWorker, showReload, reloadPage]);

    return ( <ToastContainer />);
}

export default RefreshButton;