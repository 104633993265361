import React, {useState} from "react";
import { FirebaseAuth } from "../../../components/FirebaseAuth/firebase";
import { Button, Table } from "react-bootstrap";
import { Input } from "../../../components/Form";
import AlertPreformatted from "../../../components/AlertPreformatted";
import { CloudFunctions } from "../../../components/FirebaseAuth/firebase";

export const AdminTable = ({accounts}) => {
    
    const [searchName, setSearchName] = useState({
        hasError: false,
        error: null,
        value: "",
    });
    const [searchId, setSearchId] = useState({
        hasError: false,
        error: null,
        value: "",
    });
    

    const [sort, setSort] = useState(false);
    const [sortFirebase, setSortFirebase] = useState(false);
    const [gettingStats, setGettingStats] = useState(false);

    const sortedAccounts = accounts.sort((a, b) => {
        const statusA = a.subscriptionStatus !== undefined;
        const statusB = b.subscriptionStatus !== undefined;
        if (statusA && !statusB) {
          return sort ? 1 : -1;
        }
        if (!statusA && statusB) {
          return sort ? -1 : 1;
        }
        return 0;
    });

    const firebaseSortedAccounts = sortedAccounts.sort((a, b) => {
        const statusA = a.firebase;
        const statusB = b.firebase;
        if (statusA && !statusB) {
          return sortFirebase ? -1 : 1;
        }
        if (!statusA && statusB) {
          return sortFirebase ? 1 : -1;
        }
        return 0;
    });

    const filteredAccounts = firebaseSortedAccounts.filter((item) => {
        if(searchName.value.length > 1) {
            return item.name.toLowerCase().includes(searchName.value.toLowerCase())
        } else if(searchId.value.length > 1) {
            return item.id.toLowerCase().includes(searchId.value.toLowerCase())
        } else {
            return true
        }
    });
    const [errorMessage, setErrorMessage] = useState(null);

    const getStats = async (id, subscriptionCurrentPeriodEnd) => {
        setGettingStats(true);
        const onCall = CloudFunctions.httpsCallable('oncall');
        try{
            const mets = await onCall({name: "getApiMetrics", data: {accountId: id} });
            const regs = await onCall({ name: "getRegistrations", data: {accountId: id} })
            const dateEnd = new Date(0); // The 0 there is the key, which sets the date to the epoch
            dateEnd.setUTCSeconds(subscriptionCurrentPeriodEnd);
            const dateToday = new Date();
            const DifferenceInTime = dateEnd.getTime() - dateToday.getTime();
            const DifferenceInDays = DifferenceInTime / (1000 * 3600 * 24);
            const data = {BillingEndsIn: parseFloat(DifferenceInDays).toFixed(2) + " days", Registrations: regs.data.hasOwnProperty('platforms') ? regs.data.platforms.length : regs.data.length, Metrics: mets.data[0]};
            setErrorMessage(JSON.stringify(data, null, 2));
            setGettingStats(false);
        } catch (e) {
            setErrorMessage("ERROR: " + e);
            setGettingStats(false);
        }
    }

    const getDomain = async (id) => {
        const subdomainRef = await FirebaseAuth.firestore().collection('subdomains').doc(id).get();
        if (!subdomainRef) return null
        const subdomain = subdomainRef.data();
        if(subdomain !== undefined) {
            const sorted = Object.keys(subdomain)
            .sort()
            .reduce((accumulator, key) => {
              accumulator[key] = subdomain[key];
              return accumulator;
            }, {});

            setErrorMessage(JSON.stringify(sorted, null, 2));
        } else {
            const accountRef = await FirebaseAuth.firestore().collection('accounts').doc(id).get();
            if (!accountRef) {
                setErrorMessage("This account has not been deployed yet")
            } else {
                const account = accountRef.data();
                let found = false;
                for(let i=0; i<account.plans.length; i++) {
                    const plan = await account.plans[i].get();
                    if(plan.data().name.includes("Custom Domain")) {
                        found = true;
                    }
                }
                if(found) {
                    setErrorMessage("WARNING: This account as a Custom Domain, but has not been deployed yet")
                } else {
                    setErrorMessage("This account has not been deployed yet")
                }
            }
        }
    }

    const switchToFirebase = async (id) => {
        const subdomainRef = await FirebaseAuth.firestore().collection('subdomains').doc(id).get();
        if (!subdomainRef) return null
        const subdomain = subdomainRef.data();
        if(subdomain !== undefined) {
            setErrorMessage("This account has already been activated, it needs to be migrated manually.")
        } else {
            const accountRef = FirebaseAuth.firestore().collection("accounts").doc(id)
            const accountRefGet = await accountRef.get()
            const account = accountRefGet.data();
            let found = false;
            if(account.plans) {
                for(let i=0; i<account.plans.length; i++) {
                    const plan = await account.plans[i].get();
                    if(plan.data().name.includes("Custom Domain")) {
                        found = true;
                    }
                }
            }
            if(found) {
                setErrorMessage("WARNING: This account as a Custom Domain, but has not been deployed yet.\nIt was NOT moved to firebase.")
            } else {
                await accountRef.set({
                    firebase: true,
                    apiKey: ""
                }, {merge: true});
                setErrorMessage("Account is now on firebase")
            }
        }
        
    }


    return (
        <>
            {errorMessage !== null && (
                <AlertPreformatted
                    type="warning"
                    message={errorMessage}
                    dismissible={true}
                    onDismiss={() => setErrorMessage(null)}
                ></AlertPreformatted>
            )}
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                    <th>Account Name</th>
                    <th>Account Id</th>
                    <th>Stripe Id</th>
                    <th>Firebase</th>
                    <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <Input
                                type="text"
                                value={searchName.value}
                                placeholder="Search..."
                                name="searchName"
                                required={false}
                                changeHandler={setSearchName}>
                            </Input>
                        </td>
                        <td>
                            <Input
                                type="text"
                                value={searchId.value}
                                placeholder="Search..."
                                name="searchId"
                                required={false}
                                changeHandler={setSearchId}>
                            </Input>
                        </td>
                        <td></td>
                        <td>
                            <Button onClick={() => setSortFirebase(!sortFirebase)}>Sort</Button>
                        </td>
                        <td>
                            <Button onClick={() => setSort(!sort)}>Sort</Button>
                        </td>
                    </tr>
                    {filteredAccounts.map((account, i) => 
                        <tr key={account.id}>
                        <td>{account.name}{account.type === "consumer" && (<i> (consumer)</i>)}{account.features?.trial && (<i> (trial)</i>)}</td>
                        <td>{account.id}</td>
                        <td>{account.stripeActiveSubscriptionID}</td>
                        <td>{account.firebase ? "true" : "false"}</td>
                        <td>
                            {account.subscriptionStatus?(
                                <>
                                    <Button href={'/account/'+account.id+'/'} variant="primary">Account Overview</Button>
                                    <Button variant="info" onClick={() => getDomain(account.id)}>Domain?</Button>
                                    <Button variant="success" disabled={gettingStats} onClick={() => getStats(account.id, account.subscriptionCurrentPeriodEnd)}>Stats?</Button>
                                </>
                            ):(
                                <>
                                    <Button href={'/account/'+account.id+'/billing/plan'} variant="warning">Activate the account</Button>
                                </>
                            )}
                            {false && !account.firebase &&
                                <Button variant="danger" onClick={() => switchToFirebase(account.id)}>Switch To Firebase</Button>
                            }
                        </td>
                        </tr>
                    )}
                </tbody>
            </Table>

        </>

    )
}
