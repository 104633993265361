import React from "react";
import FirebaseUI from '../../../components/FirebaseUI';

const SignIn = () => {

    return (
        <>
            <div className="col-md-3"></div>
        
            <div className="text-center col-md-6">
                <div className="card">
                    <p></p>
                    <center><img width="100" src="/logo.png" alt="LTIAAS logo"/></center>
                    <p>Customer Portal</p>
                    <p></p>
                    <h2 className="h3 mb-3 font-weight-normal">Please sign in</h2>
                    <div className="card-body">
                        <FirebaseUI />
                    </div>
                    <div className="mb-2 ml-1 mr-1">
                        <em>
                            By signing in and/or creating an account, you're agreeing to our
                            <a href="https://ltiaas.com/terms" target="blank" className="ml-1">terms and conditions</a>,
                            <a href="https://ltiaas.com/service-level-agreement" target="blank" className="ml-1">service level agreement</a>, and
                            <a href="https://ltiaas.com/privacy-policy" target="blank" className="ml-1">privacy policy</a>.
                        </em>
                    </div>
                </div>
            </div>

            <div className="col-md-3"></div>
        </>
    )
}

export default SignIn;