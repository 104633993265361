import React, {useState, useContext, useEffect} from "react";
import { BreadcrumbContext } from '../../../components/Breadcrumb';
import { FirebaseAuth } from "../../../components/FirebaseAuth/firebase";
import { Link, Navigate } from 'react-router-dom';
import Loader from '../../../components/Loader';
import { AuthContext } from "../../../components/FirebaseAuth";
import { Accordion, Button } from "react-bootstrap";
import AlertPreformatted from "../../../components/AlertPreformatted";
import { superAdmins } from "../superAdmins"
import Plot from 'react-plotly.js';
import Placeholder from 'react-bootstrap/Placeholder';
import { AdminTable } from "./AdminTable";
import { RenameableHeader } from "./RenameableHeader";
import { UserAccountTable } from "./UserAccountTable";

const Home = () => {
    const title = 'My Accounts';

    const { setBreadcrumb } = useContext(BreadcrumbContext);
    const { authUser } = useContext(AuthContext);
    
    const [loading, setLoading] = useState(true);
    const [accounts, setAccounts] = useState([]);
    const [stats, setStats] = useState({});
    const [closedAccounts, setClosedAccounts] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    
    
    const getAccounts = async () => {
        setLoading(true);
        let records = [];
        let closedRecords = [];
        const accountsRef = FirebaseAuth.firestore().collection('accounts');
        let query = accountsRef.where('access', 'array-contains', FirebaseAuth.auth().currentUser.uid);
        const accountSnapshots = await query.get();
        accountSnapshots.forEach(account => {
            records.push({
                'id': account.id,
                'name': account.data().name,
                'subscriptionStatus': account.data().subscriptionStatus,
                'firebase': account.data().firebase,
                'features': account.data().features,
                'stripeActiveSubscriptionID': account.data().stripeActiveSubscriptionID,
                'subscriptionCurrentPeriodEnd': account.data().subscriptionCurrentPeriodEnd,
                'type': account.data().type
            });
        });
        setAccounts(records);
        let closedQuery = accountsRef
            .where("accessCount", "==", 0)
            .where('owner', '==', FirebaseAuth.auth().currentUser.uid);
        const closedAccountSnapshots = await closedQuery.get();
        closedAccountSnapshots.forEach(account => {
            closedRecords.push({
                'id': account.id,
                'name': account.data().name,
                'subscriptionCurrentPeriodEnd': account.data().subscriptionCurrentPeriodEnd
            });
        });
        setClosedAccounts(closedRecords);
        setLoading(false);
    }

    useEffect(() => {
        const getAllAccounts = async () => {
            if(superAdmins.includes(FirebaseAuth.auth().currentUser.uid)) {
                const accountsRef = FirebaseAuth.firestore().collection('accounts');
                const times = [];
                const accts = [];
                const inactives = [];
                const frees = [];
                const actives = [];
                const paids = [];
                const allAccountSnapshots = await accountsRef.orderBy('creationTime', 'asc').get();
                let totalAccounts = 0;
                let totalActiveAccounts = 0;
                let totalInactiveAccounts = 0;
                let totalFreeAccounts = 0;
                allAccountSnapshots.forEach(account => {
                    const myDate = account.data().creationTime.toDate();
                    var noTime = new Date(myDate.getFullYear(), myDate.getMonth(), myDate.getDate());
                    times.push(noTime.getTime());
                    accts.push(++totalAccounts);
                    if(account.data().subscriptionStatus === "active") {
                        actives.push(++totalActiveAccounts);
                    } else {
                        actives.push(totalActiveAccounts);
                    }
                    if(account.data().subscriptionStatus !== "active") {
                        inactives.push(++totalInactiveAccounts);
                    } else {
                        inactives.push(totalInactiveAccounts);
                    }
                    if(account.data().subscriptionStatus === "active" && account.data().features?.trial === true) {
                        frees.push(++totalFreeAccounts);
                    } else {
                        frees.push(totalFreeAccounts);
                    }
                    paids.push(totalActiveAccounts - totalFreeAccounts);
                });
                
                setStats({
                    times: times,
                    accts: accts,
                    actives: actives,
                    inactives: inactives,
                    frees: frees,
                    paids: paids
                })
            }
        }

        setBreadcrumb([
            {
                to: "/",
                text: "Home",
                active: false
            },
            {
                to: null,
                text: title,
                active: true
            }
        ]);
        getAccounts();
        getAllAccounts();
    },[setBreadcrumb]);

    

    return (
        <>
            <div className="container-fluid">
                <div className="animated fadeIn">
                    {!loading &&(
                        <div className="text-right mb-3">
                            <Link to="/new-account" className="btn btn-primary"><i className="fa fa-plus"></i> Add Account</Link>
                        </div>
                    )}
                    {superAdmins.includes(authUser.user.uid) && (
                        <>
                            {stats.times ? 
                                <Plot
                                    data={[
                                        {
                                            x: stats.times,
                                            y: stats.accts,
                                            type: 'scatter',
                                            mode: 'lines',
                                            marker: {color: '#023E73'},
                                            name: 'All Accounts'
                                        },
                                        {
                                            x: stats.times,
                                            y: stats.actives,
                                            type: 'scatter',
                                            mode: 'lines',
                                            marker: {color: '#3FBF04'},
                                            name: 'Active Accounts'
                                        },
                                        {
                                            x: stats.times,
                                            y: stats.frees,
                                            type: 'scatter',
                                            mode: 'lines',
                                            marker: {color: '#BF9604'},
                                            name: 'Free Accounts'
                                        },
                                        {
                                            x: stats.times,
                                            y: stats.paids,
                                            type: 'scatter',
                                            mode: 'lines',
                                            marker: {color: '#7E04BF'},
                                            name: 'Paid Accounts'
                                        },
                                        {
                                            x: stats.times,
                                            y: stats.inactives,
                                            type: 'scatter',
                                            mode: 'lines',
                                            marker: {color: '#BF0436'},
                                            name: 'Inactive Accounts'
                                        },
                                    ]}
                                    showlegend={true}
                                    legend={{
                                        borderwidth: 0,
                                        x: 1,
                                        xanchor: 'right',
                                        y:1.1,
                                        orientation: 'h' 
                                    }}
                                    layout={{
                                        title: 'Daily Account Creations',
                                        images: [{
                                            name: 'watermark_1',
                                            source: "/logo_large.png",
                                            xref: "paper",
                                            yref: "paper",
                                            x: 0.05,
                                            y: 0.9,
                                            sizex: 0.3,
                                            sizey: 0.3,
                                            opacity: 0.1,
                                            layer: "below"
                                        }],
                                        xaxis: {
                                            autorange: true,
                                            rangeselector: {buttons: [
                                                {
                                                    count: 1,
                                                    label: '1d',
                                                    step: 'day',
                                                    stepmode: 'backward'
                                                },
                                                {
                                                    count: 7,
                                                    label: '7d',
                                                    step: 'day',
                                                    stepmode: 'backward'
                                                },
                                                {
                                                    count: 1,
                                                    label: '1m',
                                                    step: 'month',
                                                    stepmode: 'backward'
                                                },
                                                {
                                                    count: 1,
                                                    label: '1y',
                                                    step: 'year',
                                                    stepmode: 'backward'
                                                },
                                                {step: 'all'}
                                            ]},
                                            rangeslider: {autorange: true},
                                            type: 'date'
                                        },
                                        yaxis: {
                                            autorange: true,
                                            type: 'linear'
                                        },
                                        margin: {
                                            b:10,
                                            l:60,
                                            r:10,
                                            t:60
                                        }
                                    }}
                                    useResizeHandler={true}
                                    style={{width: "100%", height: "450px"}}
                                ></Plot>
                                :
                                <Placeholder animation="glow" style={{ height: 100, width: "100%" }} />
                            }  
                            <div className="card">
                                <div className="card-header font-weight-bold">
                                    <h5>Your Accounts</h5>
                                </div>
                                {errorMessage !== null && (
                                    <AlertPreformatted
                                        type="warning"
                                        message={errorMessage}
                                        dismissible={true}
                                        onDismiss={() => setErrorMessage(null)}
                                    ></AlertPreformatted>
                                )}
                                <div className="card-body">
                                    <AdminTable accounts={accounts} />
                                </div>
                            </div>
                        </>
                    )}

                    {!superAdmins.includes(authUser.user.uid) ? (
                        <>
                            {accounts.length > 3 ? 
                                <div className="card">
                                    <div className="card-header font-weight-bold">
                                        <h5>Your Accounts</h5>
                                    </div>
                                    <div className="card-body">
                                        <UserAccountTable accounts={accounts} getAccounts={getAccounts} />
                                    </div>
                                </div>
                            :
                                <>
                                    {accounts.map((account, i) => 
                                        
                                        <div className="card" key={account.id}>
                                            <div className="card-header font-weight-bold">
                                                <h6 style={{float: "right", color:"#aaaaaa"}}>Account ID: <i>{account.id}</i></h6>
                                                <h5>
                                                    <RenameableHeader account={account} getAccounts={getAccounts} />
                                                    
                                                </h5>
                                                {account.type === "consumer" && <h6>(consumer)</h6>}
                                            </div>
                                            <div className="card-body">
                                                {account.subscriptionStatus?(
                                                    <>
                                                        {account.features?.trial &&
                                                            <Button style={{float:"right"}} href={'/account/'+account.id+'/billing/plan'} variant="success">
                                                                <i className="fas fa-arrow-circle-up"></i> Upgrade
                                                            </Button>
                                                        }
                                                        <Button href={'/account/'+account.id+'/'} variant="primary">
                                                            <i className="fa fa-tachometer-alt mr-1"></i> Account Overview
                                                        </Button>
                                                    </>
                                                ):(
                                                    <>
                                                        <Button style={{float:"right"}} href={'/account/'+account.id+'/billing/delete'} variant="danger">
                                                            <i className="fas fa-trash"></i> Delete
                                                        </Button>
                                                        <Button href={'/account/'+account.id+'/billing/plan'} variant="warning">
                                                            <i className="fas fa-check"></i> Activate the account
                                                        </Button>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        
                                    )}
                                </>
                            }
                        </>
                    ) : accounts.length === 0 && (
                        <>
                            {(loading) ? (
                                <Loader text="loading accounts..."></Loader>
                            ):(
                                <Navigate to="/new-account"></Navigate>
                            )}
                        </>
                    )}
                    {closedAccounts.length > 0 && (
                        <Accordion className="mb-2">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Closed Accounts</Accordion.Header>
                                <Accordion.Body>
                                    {closedAccounts.map((account, i) => 
                                        
                                        <div className="card" key={account.id}>
                                            <div className="card-header font-weight-bold">
                                                <h5>{account.name}</h5>
                                            </div>
                                            <div className="card-body">
                                                <div className="alert alert-warning" role="alert">
                                                    This account has been closed.
                                                </div>
                                                <Button href={'/account/'+account.id+'/billing'} variant="primary">
                                                    <i className="fa fa-file-invoice-dollar"></i> View Invoices
                                                </Button>
                                            </div>
                                        </div>
                                        
                                    )}
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    )}
                </div>
            </div>
        </>

    )
}

export default Home;