import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import SidebarLink from '../SidebarLink';
import { AuthContext } from '../../FirebaseAuth';

const AccountMenu = () => {

    const { accountId } = useParams();

    const { userData } = useContext(AuthContext);

    useEffect(() => {
        document.querySelectorAll('.c-sidebar').forEach(element => {
            window.coreui.Sidebar._sidebarInterface(element)
        });
    })

    return (

            <ul className="c-sidebar-nav ps ps--active-y">
                <li className="c-sidebar-nav-title">Account</li>
                <li className="c-sidebar-nav-item">
                    <SidebarLink className={"c-sidebar-nav-link"+(window.location.pathname==='/account/'+accountId+'/'?" active":"")} to={'/account/'+accountId+'/'}>
                        <i className="c-sidebar-nav-icon fa fa-tachometer-alt"></i>Overview
                    </SidebarLink>
                    {userData.currentAccount.role === 'admin' && <SidebarLink className={"c-sidebar-nav-link"+(window.location.pathname==='/account/'+accountId+'/settings'?" active":"")} to={'/account/'+accountId+'/settings'}>
                        <i className="c-sidebar-nav-icon fa fa-wrench"></i>API Settings
                    </SidebarLink>}
                    {(userData.currentAccount.role === 'admin' || userData.currentAccount.role === 'registrar') && <SidebarLink className={"c-sidebar-nav-link"+(window.location.pathname==='/account/'+accountId+'/registrations'?" active":"")} to={'/account/'+accountId+'/registrations'}>
                        <i className="c-sidebar-nav-icon fa fa-list-alt"></i>Registrations
                    </SidebarLink>}
                    {userData.currentAccount.role === 'admin' && <SidebarLink className={"c-sidebar-nav-link"+(window.location.pathname==='/account/'+accountId+'/logs'?" active":"")} to={'/account/'+accountId+'/logs'}>
                        <i className="c-sidebar-nav-icon fa fa-stream"></i>Logs
                    </SidebarLink>}
                </li>
                {userData.currentAccount.role === 'admin' && 
                <>
                    <li className="c-sidebar-nav-title">Settings</li>
                    <li className="c-sidebar-nav-item">
                        <SidebarLink className={"c-sidebar-nav-link"+(window.location.pathname.startsWith('/account/'+accountId+'/users')?" active":"")} to={'/account/'+accountId+'/users'}>
                            <i className="c-sidebar-nav-icon fa fa-users"></i>Manage Users
                        </SidebarLink>
                    </li>
                    <li className="c-sidebar-nav-item">
                        <SidebarLink className={"c-sidebar-nav-link"+(window.location.pathname.startsWith('/account/'+accountId+'/billing')?" active":"")} to={'/account/'+accountId+'/billing'}>
                            <i className="c-sidebar-nav-icon fa fa-file-invoice-dollar"></i>Billing
                        </SidebarLink>
                    </li>
                </>
                }
                {userData.currentAccount.role === 'financier' && 
                    <li className="c-sidebar-nav-item">
                        <SidebarLink className={"c-sidebar-nav-link"+(window.location.pathname.startsWith('/account/'+accountId+'/billing')?" active":"")} to={'/account/'+accountId+'/billing'}>
                            <i className="c-sidebar-nav-icon fa fa-file-invoice-dollar"></i>Billing
                        </SidebarLink>
                    </li>
                }
                {userData.currentAccount.features?.trial && !window.location.pathname.startsWith('/account/'+accountId+'/billing/plan') &&
                <>
                    <li className="c-sidebar-nav-item">
                        <SidebarLink className={"c-sidebar-nav-link sidebar-highlight"} to={'/account/'+accountId+'/billing/plan'}>
                            <i className="c-sidebar-nav-icon fas fa-arrow-circle-up"></i>Upgrade Account
                        </SidebarLink>
                    </li>
                </>
                }
                {userData.currentAccount.features?.priority_support && 
                <>
                    <li className="c-sidebar-nav-title">Support</li>
                    <li className="c-sidebar-nav-item">
                        <SidebarLink className={"c-sidebar-nav-link"+(window.location.pathname.startsWith('/account/'+accountId+'/users')?" active":"")} to={'/account/'+accountId+'/priority-support'}>
                        <i className="c-sidebar-nav-icon fas fa-exclamation-triangle"></i>Priority Support
                        </SidebarLink>
                    </li>
                </>
                }
                <li className="c-sidebar-nav-title">Application</li>
                <li className="c-sidebar-nav-item">
                    <SidebarLink className={"c-sidebar-nav-link"+(window.location.pathname==='/'?" active":"")} to="/">
                        <i className="c-sidebar-nav-icon fa fa-th-large"></i>My Accounts
                    </SidebarLink>
                </li>
                <li className="c-sidebar-nav-item">
                    <SidebarLink className={"c-sidebar-nav-link"+(window.location.pathname==='/secrets'?" active":"")} to="/secrets">
                        <i className="c-sidebar-nav-icon fas fa-key"></i>Secret Sharing
                    </SidebarLink>
                </li>
                <li className="c-sidebar-nav-title">User</li>
                <li className="c-sidebar-nav-item">
                    <SidebarLink className={"c-sidebar-nav-link"+(window.location.pathname.startsWith('/user/profile')?" active":"")} to="/user/profile">
                        <i className="c-sidebar-nav-icon fa fa-user"></i> Profile
                    </SidebarLink>
                </li>
                <li className="c-sidebar-nav-item">
                    <SidebarLink className={"c-sidebar-nav-link"+(window.location.pathname.startsWith('/user/log')?" active":"")} to="/user/log">
                        <i className="c-sidebar-nav-icon fa fa-list"></i> Activity Logs
                    </SidebarLink>
                </li>
            </ul>
    )
}

export default AccountMenu;