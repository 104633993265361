import React, { useState, useContext, useEffect } from "react";
import Highlight from 'react-highlight'
import { Link } from "react-router-dom";
import PublicTemplate from "../../../../components/templates/PublicTemplate";
import Loader from "../../../../components/Loader";
import { BreadcrumbContext } from "../../../../components/Breadcrumb";
import { AuthContext } from "../../../../components/FirebaseAuth";
import { CloudFunctions } from "../../../../components/FirebaseAuth/firebase";
import { FirebaseAuth } from "../../../../components/FirebaseAuth/firebase";
import Alert from "../../../../components/Alert";

const Logs = () => {
  const title = "Logs";

  const { userData } = useContext(AuthContext);
  const { setBreadcrumb } = useContext(BreadcrumbContext);

  const [settingsComplete, setSettingsComplete] = useState(null);
  const [logs, setLogs] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(true);


  const fetchLogs = async (accountId) => {
    setSettingsComplete(false);
    const doc = await FirebaseAuth.firestore().collection("subdomains").doc(accountId).get();
    if (doc.exists) {
      const api_settings = doc.data();
      if (api_settings.hasOwnProperty("subdomainName")) {
        // subdomain exists
        const getSubdomainLogs = CloudFunctions.httpsCallable('oncall');
        try {
          const res = await getSubdomainLogs({
            name: "getSubdomainLogs",
            data: {
              accountId: userData.currentAccount.id
            }
          });
          setLogs(res.data);
          setSettingsComplete(true);
          setLoading(false);
        } catch(err) {
          setErrorMessage(err.message);
          setLogs(null);
          setSettingsComplete(true);
          setLoading(false);
        }
      }  else {
        setLogs("");
        setSettingsComplete(false);
        setLoading(false);
      }
    } else {
      setLogs("");
      setSettingsComplete(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLogs(userData.currentAccount.id);
    setBreadcrumb([
      {
        to: "/",
        text: "Home",
        active: false,
      },
      {
        to: "/account/" + userData.currentAccount.id + "/",
        text: userData.currentAccount.name + ` (${userData.currentAccount.id})`,
        active: false,
      },
      {
        to: null,
        text: title,
        active: true,
      },
    ]);
  }, [userData, setBreadcrumb, title]);

  return (
    <>
    {loading ? (
      <PublicTemplate>
          <Loader size="5x" text="Loading..."/>
      </PublicTemplate>
    ):(
    <>
      <div className="container-fluid">
        <div className="animated fadeIn">
          {errorMessage !== null && (
              <Alert
                  type="danger"
                  message={errorMessage}
                  dismissible={true}
                  onDismiss={() => setErrorMessage(null)}
              ></Alert>
              )}
          {!settingsComplete && logs !== null && (
            <div className="card">
              <div className="card-header">Finish your API setup</div>
              <div className="card-body">
                <p>
                  Before you API can go live, you need to set your API settings.
                  Please edit your &nbsp;
                  <b><Link
                    to={"/account/" + userData.currentAccount.id + "/settings"}
                  >
                    API Settings
                  </Link></b>
                  .
                </p>
              </div>
            </div>
          )}
          <div className="card">
            <div className="card-header">{title}</div>
            <div className="card-body">
              {!userData.currentAccount.subscriptionStatus && (
                <p>
                  Account status is not active,{" "}
                  <Link to={"/account/" + userData.currentAccount.id + "/plan"}>
                    activate a plan here to continue
                  </Link>
                  .
                </p>
              )}
              {logs && 
                <Highlight className='language-accesslog'>{logs}</Highlight>
              }
              {logs === null && <p>Fetching logs from server...</p>}
            </div>
          </div>
        </div>
      </div>
    </>
  )}
  </>
  );
};

export default Logs;
