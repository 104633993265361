import React from "react";
import PropTypes from "prop-types";

const Field = (props) => {

    return (
        <div className="form-group row">
            <label className="col-md-2 col-lg-2 col-form-label">
                <strong>{props.label}</strong>
                {props.logo && <div style={{"textAlign": "center","height": "max(calc(100% - 15px), 60px)"}} className="rounded-top bg-light">
                    <img alt="LTIAAS Logo" style={{"maxHeight":"100%", "maxWidth": "100%","marginTop":"5px"}} src={props.logo}></img>
                </div>}
            </label>
            <div className="col-md-10 col-lg-10">
                {props.description!=null && <p>{props.description}</p>}
                {props.extra!=null && <div className="alert alert-info" role="alert">{props.extra}</div>}
                {props.children}
            </div>
        </div>
    )
}

Field.propTypes = {
    label: PropTypes.string
}

export default Field;