import React, { useContext, useEffect, useState } from "react";
import { BreadcrumbContext } from '../../../../components/Breadcrumb';
import { AuthContext } from "../../../../components/FirebaseAuth";
import { CloudFunctions } from "../../../../components/FirebaseAuth/firebase";
import Loader from "../../../../components/Loader";
import UserAvatar from '../../../../components/UserAvatar';
import Alert from "../../../../components/Alert";
import { Link } from "react-router-dom";
import { superAdmins } from "../../superAdmins"

const UserList = () => {
    const title = 'Users';

    const { userData } = useContext(AuthContext);
    const { setBreadcrumb } = useContext(BreadcrumbContext);
    const [users, setUsers] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        setBreadcrumb(
            [
                {
                    to: "/",
                    text: "Home",
                    active: false
                },
                {
                    to: "/account/"+userData.currentAccount.id+"/",
                    text: userData.currentAccount.name + ` (${userData.currentAccount.id})`,
                    active: false
                },      
                {
                    to: null,
                    text: title,
                    active: true
                }
            ]);
        setError(null);
        const getAccountUsers = CloudFunctions.httpsCallable('oncall');
        getAccountUsers({
            name: "getAccountUsers",
            data: {
                accountId: userData.currentAccount.id
            }
        }).then(res => {
            res.data.forEach(record => {
                record.lastLoginTime = new Date(record.lastLoginTime);
            });
            const found_users = res.data.filter(record => !superAdmins.includes(record.id));
            setUsers(found_users);
        }).catch(err => {
            setError(err.message);
        });
    },[userData, setBreadcrumb])

    return (
        <>
            <div className="container-fluid">
                <div className="animated fadeIn">
                    {!userData.currentAccount.features?.multiple_roles &&
                        <>
                            <div className="alert fade show alert-warning" role="alert">
                                <p>
                                <b>Sorry, your current plan only supports one administrator role.
                                    {" "}To add more users to this account, please upgrade your account to a tier that supports the  'Multiple Admins/Roles' feature.</b>
                                </p>
                                <a href={'/account/'+userData.currentAccount.id+'/billing/plan'} className="btn btn-success">
                                    <i className="fas fa-arrow-circle-up mr-1"></i>Upgrade Account
                                </a>
                            </div>
                        </>
                    }
                    <>
                        {userData.currentAccount.features?.multiple_roles &&
                        <div className="text-right mb-3">
                            <Link to={"/account/"+userData.currentAccount.id+"/users/add"} className="btn btn-primary"><i className="fa fa-plus"></i> Add User</Link>
                        </div>
                        }
                        <div className="card">
                            <div className="card-header">
                                {title}
                            </div>
                            <div className="card-body">
                                {error !== null && 
                                    <Alert type="danger" message={error} dismissible={true} onDismiss={() => setError(null)}></Alert>
                                }
                                {users === null ? (
                                    <Loader text="Loading users" />
                                ):(
                                    <table className="table table-responsive-sm table-hover table-outline mb-0">
                                        <thead className="thead-light">
                                            <tr>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Role</th>
                                                <th>Last Login</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {users.map((user, i) => 
                                            <tr key={i}>
                                                <th>
                                                    <div className="row col">
                                                        <UserAvatar className="c-avatar-img" name={user.displayName} photoUrl={user.photoUrl} ></UserAvatar>
                                                        <div className="pt-2 ml-1">
                                                            <strong>{user.displayName}</strong>
                                                        </div>
                                                    </div>
                                                </th>
                                                <td>{user.email}</td>
                                                <td>{user.id===userData.currentAccount.owner?"Owner":(user.role.charAt(0).toUpperCase()+user.role.slice(1))}</td>
                                                <td>{user.lastLoginTime.toLocaleTimeString()} {user.lastLoginTime.toLocaleDateString()}</td>
                                                <td className="text-right">
                                                    {user.id!==userData.currentAccount.owner && userData.currentAccount.features?.multiple_roles &&
                                                        <Link className="btn btn-primary" to={"/account/"+userData.currentAccount.id+"/users/change/"+user.id}>Change Role</Link>
                                                    }
                                                </td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                )}
                            </div>
                        </div>
                    </>
                    <table className="table table-responsive-sm table-hover table-outline mb-0">
                        <thead className="thead-light">
                            <tr>
                                <th>Role</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>Owner</th>
                                <td>This is the user that created the account. This is the only account that has permission to change billing information, and delete the account. The owner cannot be changed once the account is created. If you need to change the owner, please contact <a href="mailto:support@ltiaas.com">support@ltiaas.com</a>. To change an account owner, you must show proof of domain and company ownership.</td>
                            </tr>
                            <tr>
                                <th>Admin</th>
                                <td>This role has the most access to the account aside from the owner, including the ability to view invoices, platform registrations, and edit settings.</td>
                            </tr>
                            <tr>
                                <th>Registrar</th>
                                <td>This role only has access to register/edit/delete platform registrations.</td>
                            </tr>
                            <tr>
                                <th>Financier</th>
                                <td>This role only has access to view (and pay) invoices.</td>
                            </tr>
                            <tr>
                                <th>User</th>
                                <td>This is a view-only role. It is mostly used to keep a user on the account list but remove their access to change the account.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>

    )
}

export default UserList;