import React, { useState, useContext, useEffect } from "react";
import { BreadcrumbContext } from "../../../../components/Breadcrumb";
import { Form, Field, Input, Select } from "../../../../components/Form";
import { AuthContext } from "../../../../components/FirebaseAuth";
import { CloudFunctions } from "../../../../components/FirebaseAuth/firebase";
import Alert from "../../../../components/Alert";
import Highlight from "react-highlight";
import PropTypes from "prop-types";

const EditRegistration = (props) => {

  const {
    data
  } = props;

  const title = data.id ? `Editing Registration: ${data.id}` : "New Registration";
  const platformId = data.id;

  const [url, setUrl] = useState({
      hasError: false,
      error: null,
      value: data.url || ""
  });
  const [clientId, setClientId] = useState({
      hasError: false,
      error: null,
      value: data.clientId || ""
  });
  const [name, setName] = useState({
      hasError: false,
      error: null,
      value: data.name || ""
  });
  const [authenticationEndpoint, setAuthenticationEndpoint] = useState({
      hasError: false,
      error: null,
      value: data.authenticationEndpoint || ""
  });
  const [accesstokenEndpoint, setAccesstokenEndpoint] = useState({
      hasError: false,
      error: null,
      value: data.accesstokenEndpoint || ""
  });
  const [authorizationServer, setAuthorizationServer] = useState({
    hasError: false,
    error: null,
    value: data.authorizationServer || ""
  });
  const [authConfigMethod, setAuthConfigMethod] = useState({
      hasError: false,
      error: null,
      value: data.authConfig ? data.authConfig.method : "JWK_SET"
  });
  const [authConfigKey, setAuthConfigKey] = useState({
    hasError: false,
    error: null,
    value: data.authConfig ? data.authConfig.key : ""
});

  const [inSubmit, setInSubmit] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [APIResponse, setAPIResponse] = useState(null);
  const { userData } = useContext(AuthContext);
  const { setBreadcrumb } = useContext(BreadcrumbContext);



  function validateForm() {
    return (
      url.value === "" ||
      clientId.value === "" ||
      name.value === "" ||
      authenticationEndpoint.value === "" ||
      accesstokenEndpoint.value === "" ||
      authConfigMethod.value === "" ||
      authConfigKey.value === "" ||
      inSubmit
    );
  }

  const sendRegistration = async () => {
    setInSubmit(true);
    const data = {
      url: url.value,
      clientId: clientId.value,
      name: name.value,
      authenticationEndpoint: authenticationEndpoint.value,
      accesstokenEndpoint: accesstokenEndpoint.value,
      authorizationServer: authorizationServer.value === "" ? accesstokenEndpoint.value : authorizationServer.value,
      authConfig: {
        method: authConfigMethod.value,
        key: authConfigKey.value
      }
    };

    if(platformId) {
      const editPlatformRegistration = CloudFunctions.httpsCallable('oncall');
      editPlatformRegistration({
        name: "editPlatformRegistration",
        data: {
          accountId: userData.currentAccount.id,
          platformId: platformId,
          data: data
        }
      }).then(res => {
        setInSubmit(false);
        if(res.data.hasOwnProperty('error')) {
          setErrorMessage(`Platform registeration edit failed: ${res.data.details.message}`);
          setAPIResponse(JSON.stringify(res.data, null, 2));
        } else {
          setSuccessMessage(`Platform registeration edited successfully.`);
          setAPIResponse(JSON.stringify(res.data, null, 2));
        }
      }).catch(err => {
        setErrorMessage(err.message);
        setInSubmit(false);
      });
    } else {
      const registerPlatform = CloudFunctions.httpsCallable('oncall');
      registerPlatform({
        name: "registerPlatform",
        data: {
          accountId: userData.currentAccount.id,
          data: data
        }
      }).then(res => {
        setInSubmit(false);
        if(res.data.hasOwnProperty('error')) {
          setErrorMessage(`Platform registeration failed: ${res.data.details.message}`);
          setAPIResponse(JSON.stringify(res.data, null, 2));
        } else {
          setSuccessMessage(`Platform registered successfully.`);
          setAPIResponse(JSON.stringify(res.data, null, 2));
        }
      }).catch(err => {
        setErrorMessage(err.message);
        setInSubmit(false);
      });
    }
  }

  useEffect(() => {
    setBreadcrumb([
      {
        to: "/",
        text: "Home",
        active: false,
      },
      {
        to: "/account/" + userData.currentAccount.id + "/",
        text: userData.currentAccount.name + ` (${userData.currentAccount.id})`,
        active: false,
      },
      {
        to: null,
        text: title,
        active: true,
      },
    ]);
  }, [setBreadcrumb, title]);

  const validUrlRegex = "https?:\\/\\/((?!(localhost|(127\\.)|(192\\.168\\.)|(10\\.)|(172\\.1[6-9]\\.)|(172\\.2[0-9]\\.)|(172\\.3[0-1]\\.)|(::1$)|([fF][cCdD]))).)[a-zA-Z0-9\\.\\-\\_]+.+"

  return (
    <>
      <div className="container-fluid">
        <div className="animated fadeIn">
          {errorMessage !== null && (
            <Alert
                type="danger"
                message={errorMessage}
                dismissible={true}
                onDismiss={() => setErrorMessage(null)}
            ></Alert>
          )}
          {successMessage !== null && (
            <Alert
                type="success"
                message={successMessage}
                dismissible={true}
                onDismiss={() => setSuccessMessage(null)}
            ></Alert>
          )}
          {APIResponse !== null && (
            <div>
              <Highlight className='language-json'>{APIResponse}</Highlight>
            </div>
          )}
          {APIResponse === null && (
            <div className="card">
              <div className="card-body">
                <Form
                  handleSubmit={(e) => {
                    e.preventDefault();
                    setInSubmit(true);
                    setErrorMessage(null);
                    sendRegistration().catch((err) => {
                      setErrorMessage(err.message);
                      setInSubmit(false);
                    });
                  }}
                  disabled={validateForm()}
                  inSubmit={inSubmit}
                  enableDefaultButtons={true}
                >
                  <Field
                    label="URL"
                    description="Platform URL"
                  >
                    <Input
                      type="text"
                      value={url.value}
                      name="url"
                      required={true}
                      validRegex={validUrlRegex}
                      customErrorMessage={"All URLs must be HTTPS and accessible to our servers on the internet. Localhost is not allowed. Use a dynamic DNS service like NGROK to exposure your server to the internet."}
                      changeHandler={setUrl}
                    />
                  </Field>
                  <Field
                    label="Client ID"
                    description="Platform client ID"
                  >
                    <Input
                      type="text"
                      value={clientId.value}
                      name="clientId"
                      required={true}
                      changeHandler={setClientId}
                    />
                  </Field>
                  <Field
                    label="Name"
                    description="Platform name"
                  >
                    <Input
                      type="text"
                      value={name.value}
                      name="name"
                      required={true}
                      changeHandler={setName}
                    />
                  </Field>
                  <Field
                    label="Authentication Endpoint"
                    description="Platform authentication endpoint"
                  >
                    <Input
                      type="text"
                      value={authenticationEndpoint.value}
                      name="authenticationEndpoint"
                      required={true}
                      validRegex={validUrlRegex}
                      customErrorMessage={"All URLs must be HTTPS and accessible to our servers on the internet. Localhost is not allowed. Use a dynamic DNS service like NGROK to exposure your server to the internet."}
                      changeHandler={setAuthenticationEndpoint}
                    />
                  </Field>
                  <Field
                    label="Access Token Endpoint"
                    description="Platform access token endpoint"
                  >
                    <Input
                      type="text"
                      value={accesstokenEndpoint.value}
                      name="Access Token Endpoint"
                      required={true}
                      validRegex={validUrlRegex}
                      customErrorMessage={"All URLs must be HTTPS and accessible to our servers on the internet. Localhost is not allowed. Use a dynamic DNS service like NGROK to exposure your server to the internet."}
                      changeHandler={setAccesstokenEndpoint}
                    />
                  </Field>
                  <Field
                    label="Authorization Server"
                    description="For all LMSes except D2L, this value can be left blank and LTIAAS will set it to match the 'Accesstoken Endpoint'. "
                  >
                    <Input
                      type="text"
                      value={authorizationServer.value}
                      name="Authorization Server"
                      required={false}
                      validRegex={validUrlRegex}
                      customErrorMessage={"All URLs must be HTTPS and accessible to our servers on the internet. Localhost is not allowed. Use a dynamic DNS service like NGROK to exposure your server to the internet."}
                      changeHandler={setAuthorizationServer}
                    />
                  </Field>
                  <Field
                    label="Authentication Method"
                    description="Platform token authentication method"
                  >
                    <Select
                      value={authConfigMethod.value}
                      name="AuthConfig Method"
                      required={true}
                      options={[
                        {value: "JWK_SET", label: "JWK_SET"},
                        {value: "JWK_KEY", label: "JWK_KEY"},
                        {value: "RSA_KEY", label: "RSA_KEY"}
                      ]}
                      changeHandler={setAuthConfigMethod}
                    />
                  </Field>
                  <Field
                    label="Authentication Key"
                    description="Platform token authentication key"
                  >
                    <Input
                      type="text"
                      value={authConfigKey.value}
                      name="AuthConfig Key"
                      required={true}
                      changeHandler={setAuthConfigKey}
                    />
                  </Field>
                  
                  {errorMessage !== null && (
                      <Alert
                          type="danger"
                          message={errorMessage}
                          dismissible={true}
                          onDismiss={() => setErrorMessage(null)}
                      ></Alert>
                  )}
                </Form>
              </div> 
            </div>
          )}
        </div>
      </div>
    </>
  );
};

EditRegistration.propTypes = {
  data: PropTypes.object
}

export default EditRegistration;
