import firebase from "firebase/compat/app";
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import {config} from './firebase-config';

const FirebaseAuth = firebase.initializeApp(config);
const Firestore = firebase.firestore();
const CloudFunctions = firebase.functions();

export {FirebaseAuth, Firestore, CloudFunctions}