import React, { useContext, useEffect, useState } from "react";
import { BreadcrumbContext } from '../../../../components/Breadcrumb';
import { AuthContext } from "../../../../components/FirebaseAuth";
import { CloudFunctions } from "../../../../components/FirebaseAuth/firebase";
import Alert from "../../../../components/Alert";
import { Link, Navigate } from "react-router-dom";
import Loader from "../../../../components/Loader";
import { superAdmins } from "../../superAdmins"

const DeleteAccount = () => {
    const title = 'Delete Account';

    const { userData, authUser } = useContext(AuthContext);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [inSubmit, setInSubmit] = useState(false);
    const { setBreadcrumb } = useContext(BreadcrumbContext);

    useEffect(() => {
        setBreadcrumb([
            {
                to: "/",
                text: "Home",
                active: false
            },
            {
                to: "/account/"+userData.currentAccount.id+"/",
                text: userData.currentAccount.name + ` (${userData.currentAccount.id})`,
                active: false
            },
            {
                to: "/account/"+userData.currentAccount.id+"/billing",
                text: 'Billing',
                active: false
            },
            {
                to: null,
                text: title,
                active: true
            }
        ]);
    },[userData,setBreadcrumb,title])

    const deleteAccount = async () => {
        setInSubmit(true);
        try {
            if (userData.currentAccount.subscriptionStatus === undefined) {
                const onCall = CloudFunctions.httpsCallable('oncall');
                await onCall({
                    name: "closeAccount",
                    data: {
                        accountId: userData.currentAccount.id
                    }
                });
                setInSubmit(false);
                setSuccess(true);
            } else {
                const onCall = CloudFunctions.httpsCallable('oncall');
                await onCall({
                    name: "deleteOldDomain",
                    data: {
                      accountId: userData.currentAccount.id,
                    }
                })
                await onCall({
                    name: "cancelSubscription",
                    data: {
                        accountId: userData.currentAccount.id
                    }
                });
                setInSubmit(false);
                setSuccess(true);
            }
        } catch(err) {
            setInSubmit(false);
            setError(err.message);
        }
    }
                                                

    return (
        <>
            {success?(
                <Navigate to="/"></Navigate>
            ):(
                <>
                    <div className="container-fluid">
                        <div className="animated fadeIn">
                            {((userData.currentAccount.owner === authUser.user.uid) || (superAdmins.includes(authUser.user.uid)))?(
                                <div className="card">
                                        <div className="card-header">
                                            {title}
                                        </div>
                                        <div className="card-body">
                                            {error !== null && 
                                                <Alert type="danger" message={error} dismissible={true} onDismiss={() => setError(null)}></Alert>
                                            }
                                            <p>Deleting the account will stop the subscription and the API service will be discontinued immediately.</p>
                                            
                                            <p className="text-danger">Are you sure you want to delete your account?</p>
                                            <button className="btn btn-danger mr-3" disabled={inSubmit} onClick={deleteAccount}>
                                                {inSubmit && 
                                                    <Loader />
                                                }
                                                Yes, I want to delete the account</button>
                                            {userData.currentAccount.subscriptionStatus !== undefined ?
                                                <Link className="btn btn-secondary" to={"/account/"+userData.currentAccount.id+"/billing"}>No</Link>
                                                :
                                                <Link className="btn btn-secondary" to={"/"}>No</Link>
                                            }
                                        </div>
                                </div>
                            ):(
                                <Alert type="danger" message="Access Denied. You are not the account owner." dismissible={false} ></Alert>
                            )}
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default DeleteAccount;