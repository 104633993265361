import React from "react";
import PropTypes from "prop-types";
import { Button, Form, InputGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import { faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const InputSubdomain = React.forwardRef((props, ref) => {
  const {
    hasError,
    validRegex,
    minLen,
    maxLen,
    required,
    locked,
    requestUnlock,
    error,
    changeHandler,
    keyDownHandler,
    ...others
  } = props;

  const tooltip = (locked) => {
    if(locked) {
      return (
        <Tooltip id="tooltip">
          Locked: Unlock To Allow Changing Domain
        </Tooltip>
      )
    } else {
      return (
        <Tooltip id="tooltip">
          Unlocked: Changes Allowed
        </Tooltip>
      )
    }
  }

  return (
    <>
      <InputGroup className="mb-3">
        <Form.Control
          ref={ref}
          className={"form-control" + (hasError ? " is-invalid" : "")}
          {...others}
          onChange={(e) => {
            changeHandler({
              value: e.target.value,
            });
          }}
          onKeyDown={(e) => {
              keyDownHandler(e);
          }}
        />
        <OverlayTrigger placement="top" overlay={tooltip(locked)}>
        <Button style={{borderTopLeftRadius:0, borderBottomLeftRadius:0}} variant="secondary" id="button-lock" onClick={() => {requestUnlock(locked)}}>
          {locked ? 
            <FontAwesomeIcon icon={faLock} />
            :
            <FontAwesomeIcon icon={faLockOpen} />
          }
        </Button>
        </OverlayTrigger>
        {hasError && <div className="invalid-feedback">{error}</div>}
      </InputGroup>
    </>
  );
});

InputSubdomain.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  validRegex: PropTypes.string,
  hasError: PropTypes.bool,
  error: PropTypes.string,
  minLen: PropTypes.number,
  maxLen: PropTypes.number,
  required: PropTypes.bool,
  changeHandler: PropTypes.func,
  keyDownHandler: PropTypes.func
};

export default InputSubdomain;
