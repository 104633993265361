import React, {useEffect, useState, useContext} from "react";
import { Navigate, useParams } from "react-router-dom";
import { FirebaseAuth } from "../../FirebaseAuth/firebase";
import UserMenu from '../../menus/UserMenu';
import PublicTemplate from "../../templates/PublicTemplate";
import Loader from "../../Loader";
import { AuthContext } from "../../FirebaseAuth";
import AccountMenu from "../../menus/AccountMenu";
import Logo from '../../Logo';
import {BreadcrumbContext, Breadcrumb} from '../../Breadcrumb';

const ActiveAccountTemplate = ({ roles, children }) => {

    const { accountId } = useParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { setUserData, authUser } = useContext(AuthContext);
    const [isActive, setIsActive] = useState(false);
    const [isPastDue, setIsPastDue] = useState(false);
    const [breadcrumb, setBreadcrumb] = useState([]);
    useEffect(() => {
        if(authUser.user !== null) {
            let account = {}
            setLoading(true);
            const accountRef = FirebaseAuth.firestore().doc('accounts/'+accountId);
            accountRef.get().then(doc => {
                if(doc.exists){
                    if(doc.data().subscriptionStatus && doc.data().subscriptionStatus === 'active'){
                        setIsActive(true);
                    }
                    if(doc.data().subscriptionStatus && doc.data().subscriptionStatus === 'past_due'){
                        setIsPastDue(true);
                    }
                    account.id = doc.id;
                    account.owner = doc.data().owner;
                    account.name = doc.data().name;
                    if(doc.data().plan){
                        account.planId = doc.data().plan.id;
                    }
                    account.price = doc.data().price;
                    account.currency = doc.data().currency;
                    account.paymentCycle = doc.data().paymentCycle;
                    account.subscriptionStatus = doc.data().subscriptionStatus;
                    account.subscriptionCurrentPeriodEnd = doc.data().subscriptionCurrentPeriodEnd;
                    account.features = doc.data().features;
                    if(!account.features) {
                        account.features = {
                            trial: false,
                            custom_domain: false,
                            priority_support: false,
                            error_notifications: false,
                            multiple_roles: true,
                            legacy_lti_support: false,
                        }
                    }
                    if(doc.data().admins.includes(authUser.user.uid)) {
                        account.role = 'admin';
                    } else if(doc.data().registrars.includes(authUser.user.uid)) {
                        account.role = 'registrar';
                    } else if(doc.data().financiers.includes(authUser.user.uid)) {
                        account.role = 'financier';
                    } else {
                        account.role = 'user'
                    }
                    setUserData(userData => ({
                        ...userData,
                        currentAccount: account
                    }));
                    if(!roles.includes(account.role) && !roles.includes('*')){
                        setError('Permission denied.');
                    }
                    setLoading(false);
                }else{
                    setError('Invalid account.');
                    setLoading(false);
                }
            }).catch(err => {
                setError(err.message);
                setLoading(false);
            })
        }
    },[accountId, setUserData, roles, authUser]);

    return (
        <>
            {loading ? (
                <PublicTemplate>
                    <Loader size="5x" text="Loading..."/>
                </PublicTemplate>
            ):(
                <>
                {error === null ? (
                    <>
                    {isActive ? (
                        <div className="c-app">
                            <div className="c-sidebar c-sidebar-dark c-sidebar-fixed c-sidebar-lg-show" id="sidebar">
                                <div className="c-sidebar-brand d-md-down-none">
                                    <Logo />
                                </div>
                                <AccountMenu />
                                <button className="c-sidebar-minimizer c-class-toggler" data-target="_parent" data-class="c-sidebar-minimized" type="button" />
                            </div>
                            <div className="c-wrapper">
                                <header className="c-header c-header-light c-header-fixed">
                                    <button className="c-header-toggler c-class-toggler d-lg-none mfe-auto" type="button" data-target="#sidebar" data-class="c-sidebar-show">
                                        <i className="mt-3 fa fa-bars" />
                                    </button>
                                    <div>
                                        <button className="c-header-toggler c-class-toggler mfs-3 d-md-down-none" data-target="#sidebar" data-class="c-sidebar-lg-show">
                                            <i className="mt-3 fa fa-bars" />
                                        </button>
                                    </div>
                                    <Breadcrumb links={breadcrumb} />
                                    <ul className="c-header-nav">
                                        <UserMenu />
                                    </ul>
                                </header>
                                <div className="c-body">
                                    <main className="c-main">
                                    <BreadcrumbContext.Provider value={{setBreadcrumb}}>
                                    {children}
                                    </BreadcrumbContext.Provider>
                                    </main>
                                </div>
                            </div>
                        </div>
                    ):(
                        isPastDue ? <Navigate to={'/account/'+accountId+'/past_due'}></Navigate> : <Navigate to={'/account/'+accountId+'/billing/plan'}></Navigate>
                    )}
                    </>
                ):(
                    <PublicTemplate>
                        {error}
                    </PublicTemplate>
                )}
                </>
            )}
        </>
		
    )
}

export default ActiveAccountTemplate;