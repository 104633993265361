import React from "react";
import { Button } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Alert from "../Alert";
import { CloudFunctions, FirebaseAuth } from "../FirebaseAuth/firebase";
import { AuthContext } from "../FirebaseAuth";

// a React Component called CustomDomain
const CustomDomain = (props) => {

  const {
    enabled,
    subdomainName,
    settingsCommited,
    account
  } = props;

  const [errorMessage, setErrorMessage] = React.useState(null);
  const [dnsCorrect, setDnsCorrect] = React.useState(false);
  const [checkingDNS, setCheckingDNS] = React.useState(true);
  const [customDomainValidation, setCustomDomainValidation] = React.useState(null);
  const [checkingDNSStatus, setCheckingDNSStatus] = React.useState(true);
  const [distributionDomainName, setDistributionDomainName] = React.useState(null)
  const [isDNSNameCopied, setIsDNSNameCopied] = React.useState(false);
  const [isDNSValueCopied, setIsDNSValueCopied] = React.useState(false);

  const { userData } = React.useContext(AuthContext);

  const onCopyDNSNameText = () => {
    setIsDNSNameCopied(true);
    setTimeout(() => {
      setIsDNSNameCopied(false);
    }, 2000);
  };

  const onCopyDNSValueText = () => {
    setIsDNSValueCopied(true);
    setTimeout(() => {
      setIsDNSValueCopied(false);
    }, 2000);
  };

  // Check if DNS is valid for subdomain every time it changes
  React.useEffect(() => {
    async function checkDNS() {
      if(enabled) {
        const response = await fetch(`https://dns.google/resolve?name=${subdomainName}`);
        const json = await response.json();
        let expected_answer = "143.198.245.249";
        if(distributionDomainName !== null) {
          expected_answer = distributionDomainName +".";
        }
        if(json.hasOwnProperty("Answer") && json.Answer[0].data === expected_answer) {
          setDnsCorrect(true);
        } else {
          setDnsCorrect(false);
        }
      } else {
        setDnsCorrect(true);
      }
      setCheckingDNS(false);
    }
    checkDNS();
  }, [subdomainName, checkingDNS, distributionDomainName, enabled]);
    

  const checkDNS = () => {
    setCheckingDNS(true);
  }

  React.useEffect(() => {
    if(enabled === true) {
      getCustomDomainStatus();
    }
  }, [settingsCommited, enabled])

  const getCustomDomainStatus = async () => {
    setCheckingDNSStatus(true);
    setCustomDomainValidation(null);
    setDistributionDomainName(null);
    const manageCustomDomain = CloudFunctions.httpsCallable('oncall');
    manageCustomDomain({
      name: "manageCustomDomain",
      data: {
        accountId: userData.currentAccount.id
      }
    }).then((res) => {
      if(res.data.state === "cert-validate" && res.data.data.Certificate.DomainValidationOptions[0].ResourceRecord !== null) {
        setCustomDomainValidation(res.data.data.Certificate.DomainValidationOptions[0]);
      } else if(res.data.state === "domain-created") {
        setCustomDomainValidation(res.data.data.Certificate.DomainValidationOptions[0]);
        const collectionRef = FirebaseAuth.firestore()
        .collection("accounts")
        .doc(userData.currentAccount.id)
        collectionRef.get().then(account_doc => {
          if (account_doc.exists) {
            const account = account_doc.data();
            if(account.hasOwnProperty("distributionDomainName")) {
              setDistributionDomainName(account.distributionDomainName);
            }
          }
        });
      }
      checkDNS();
      setCheckingDNSStatus(false);
    }).catch((err) => {
      if(!err.message.includes("The specified SSL certificate doesn't exist")) {
        setErrorMessage(err.message);
      }
      setCheckingDNSStatus(false);
    });
  }

  React.useEffect(() => {
    if(account && account.hasOwnProperty("distributionDomainName")) {
      setDistributionDomainName(account.distributionDomainName);
    }
  }, [account])

  return (
    
    <>
      {errorMessage !== null && (
        <Alert
            type="danger"
            message={errorMessage}
            dismissible={true}
            onDismiss={() => setErrorMessage(null)}
        ></Alert>
      )}
      {enabled && !dnsCorrect &&
        <div className="card border-danger">
          <div className="card-header text-danger">Custom Domain Setup</div>
          <div className="card-body">
            {customDomainValidation === null &&
              <p>
                {checkingDNSStatus ? (<i>Loading...</i>) : <>
                  <i>Initializing custom domain. This may take 5 minutes...</i>
                  <br></br>
                  <br></br>
                  <Button onClick={getCustomDomainStatus} disabled={checkingDNSStatus}>Check Status</Button>
                </>}
              </p>
            }
            {customDomainValidation !== null && customDomainValidation.ValidationStatus === "PENDING_VALIDATION" &&
              <p>
              {checkingDNSStatus ? (<i>Loading...</i>) :
                (<>
                We need you to validate your ownership of <i>{subdomainName}</i>.
                {" "}To do this, please add the following <b>{customDomainValidation.ResourceRecord.Type}</b>
                {" "}DNS record to the configuration of the parent domain of {subdomainName}.
                <br></br>
                <br></br>
                <b>Name:</b> <code>{customDomainValidation.ResourceRecord.Name}</code>
                <CopyToClipboard text={customDomainValidation.ResourceRecord.Name} onCopy={onCopyDNSNameText}>
                  <div className="copy-area">
                    <button className="btn btn-info mr-1">
                      <i className="far fa-copy"></i>
                    </button>
                    {isDNSNameCopied ? (
                      <span style={{ color: "var(--danger)" }}>Copied.</span>
                    ) : null}
                  </div>
                </CopyToClipboard>
                <br></br>
                <br></br>
                <b>Value:</b> <code>{customDomainValidation.ResourceRecord.Value}</code>
                <CopyToClipboard text={customDomainValidation.ResourceRecord.Value} onCopy={onCopyDNSValueText}>
                  <div className="copy-area">
                    <button className="btn btn-info mr-1">
                      <i className="far fa-copy"></i>
                    </button>
                    {isDNSValueCopied ? (
                      <span style={{ color: "var(--danger)" }}>Copied.</span>
                    ) : null}
                  </div>
                </CopyToClipboard>
                <br></br>
                <br></br>
                Once this is done, click the 'Check Status' button below.
                {" "}It could take up to 1 hour to validate the domain,
                {" "}so please be patient and feel free to click the 'Check Status' button or refresh this page
                {" "}until the domain is validated.
                <br></br>
                <br></br>
                <Button onClick={getCustomDomainStatus} disabled={checkingDNSStatus}>Check Status</Button>
              </>)}
              </p>
            }
            {customDomainValidation !== null && customDomainValidation.ValidationStatus === "FAILED" &&
              <Alert
                type="danger"
                message="Sorry, it has been more than 72 hours and the DNS validation has not been able to be validated. Please contact support@ltiaas.com to reset and try again."
                dismissible={false}
              ></Alert>
            }
            {customDomainValidation !== null && customDomainValidation.ValidationStatus === "SUCCESS" && distributionDomainName === null &&
              <p>
                {checkingDNSStatus ? (<i>Loading...</i>) : (<>DNS validation was successful. Setting up Custom API service...</>)}
                <br></br>
                <br></br>
                <Button onClick={getCustomDomainStatus} disabled={checkingDNSStatus}>Check Status</Button>
              </p>
            }
            {customDomainValidation !== null && customDomainValidation.ValidationStatus === "SUCCESS" && distributionDomainName !== null && 
              <p>
                Please add a CNAME DNS entry to point <b>{subdomainName}</b> to the LTIAAS API for your domain:
                <br></br>
                <br></br>
                <b>Value:</b> <code>{distributionDomainName}</code>
                <CopyToClipboard text={distributionDomainName} onCopy={onCopyDNSValueText}>
                  <div className="copy-area">
                    <button className="btn btn-info mr-1">
                      <i className="far fa-copy"></i>
                    </button>
                    {isDNSValueCopied ? (
                      <span style={{ color: "var(--danger)" }}>Copied.</span>
                    ) : null}
                  </div>
                </CopyToClipboard>
                <br></br>
                <br></br>
                <Alert
                  type="info"
                  message={"Note: LTIAAS needs two CNAME records, one that you created in the previous step to validate the domain, and one for "+subdomainName+". Both must remain in place for your custom domain to work properly. Do not delete either of them."}
                  dismissible={false}
                ></Alert>
                <br></br>
                <br></br>
                <Button onClick={getCustomDomainStatus} disabled={checkingDNSStatus}>Check Status</Button>
              </p>
            }
            
          </div>
        </div>
      }
      {enabled && customDomainValidation !== null && customDomainValidation.ValidationStatus === "SUCCESS" && distributionDomainName !== null && dnsCorrect &&
      <div className="card">
        <div className="card-header">Custom Domain Setup</div>
        <div className="card-body">
          <p>
            Your custom domain is set up correctly and is live. Note: LTIAAS needs the two CNAME records to remain in place for your custom domain to work properly. Do not delete either of them from your DNS configuration.
          </p>
          <table className="table table-striped table-hover table-sm table-responsive">
            <thead>
              <tr>
                <th> Name </th>
                <th> Value </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{customDomainValidation.ResourceRecord.Name}</td>
                <td>{customDomainValidation.ResourceRecord.Value}</td>
              </tr>
              <tr>
                <td>{subdomainName}</td>
                <td>{distributionDomainName}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      }
    </> 
  );
}

export default CustomDomain;
