import React, {useContext} from 'react';  
import { Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "../../FirebaseAuth";
import PublicTemplate from "../../templates/PublicTemplate";
import Loader from "../../Loader";

const PrivateRoute = () => {

    const {authUser} = useContext(AuthContext);
    
    return authUser.checked ? (
            !!authUser.user ? (
            
                <Outlet />
            ):(
                <Navigate to={"/signin?re="+document.location.pathname+document.location.search} />
            )
        ):(
            <PublicTemplate>
                <Loader size="5x" text="Loading..."/>
            </PublicTemplate>
        )
        
}
export default PrivateRoute;