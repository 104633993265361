const config = process.env.REACT_APP_ENV === 'development' ?
{
    "config" : {
        "apiKey": "AIzaSyDzvomFsRkzM2mw4fQJSOpktUJDniVtz_8",
        "authDomain": process.env.REACT_APP_LOCAL_DEV === 'true' ? "ltiaas-portal-dev.firebaseapp.com" : "portal-dev.ltiaas.com",
        "projectId": "ltiaas-portal-dev",
        "storageBucket": "ltiaas-portal-dev.appspot.com",
        "messagingSenderId": "454945338128",
        "appId": "1:454945338128:web:d852be2ad443d7848a6b95"
    }
}
:
{
    "config" : {
        "apiKey": "AIzaSyDgYZF1yWcVHO53LUjMbPUE6efuyG4TS84",
        "authDomain": process.env.REACT_APP_LOCAL_DEV === 'true' ? "ltiaas.firebaseapp.com" : "portal.ltiaas.com",
        "projectId": "ltiaas",
        "storageBucket": "ltiaas.appspot.com",
        "messagingSenderId": "34554018489",
        "appId": "1:34554018489:web:095aad64127e266856f968",
        "measurementId": "G-DND52NSXXV"
    }
}

module.exports = config;