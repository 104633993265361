import React, { useContext, useState, useEffect } from "react";
import { BreadcrumbContext } from '../../../../components/Breadcrumb';
import { AuthContext } from "../../../../components/FirebaseAuth";
import { FirebaseAuth } from "../../../../components/FirebaseAuth/firebase";
import { Link } from "react-router-dom";
import Loader from "../../../../components/Loader";
import AlertPreformatted from "../../../../components/AlertPreformatted";
import { CloudFunctions } from "../../../../components/FirebaseAuth/firebase";
const currency = require("../../../../inc/currency.json");

const PaymentList = () => {
    const title = 'Payment History';

    const { userData } = useContext(AuthContext);
    const { setBreadcrumb } = useContext(BreadcrumbContext);

    // document snapshots
    const pageSize = 10;
    const [qs, setQs] = useState(null);

    const [rows, setRows] = useState([]);
    const [toEnd, setToEnd] = useState(false);
    const [loading, setLoading] = useState(false);
    const [invoiceLoading, setInvoiceLoading] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);

    const getOutdatedInvoice = async (invoiceId) => {
        setInvoiceLoading(invoiceId);
        const getInvoice = CloudFunctions.httpsCallable('oncall');
        try{
            const invoice_link = await getInvoice({
                name: "getOutdatedInvoice",
                data: {
                    accountId: userData.currentAccount.id,
                    invoiceId: invoiceId
                }
            });
            const updated_rows = rows.map(r => {
                if(r.id === invoiceId) {
                    r.outdated = false;
                    r.hostedInvoiceUrl = invoice_link.data;
                }
                return r;
            });
            setRows(updated_rows);
        } catch(e) {
            setErrorMessage("ERROR: " + e);
        }
        setInvoiceLoading('');
    }

    const getInvoices = (accountId, pageSize, lastDoc) => {
        setLoading(true);
        let records = [];
        const collectionRef = FirebaseAuth.firestore().collection('accounts').doc(accountId).collection('invoices');
        let query = collectionRef.orderBy('created', 'desc');
        if(lastDoc){
            query = query.startAfter(lastDoc);
        }
        query = query.limit(pageSize);
        query.get().then(documentSnapshots => {
            if(documentSnapshots.empty){
                setToEnd(true);
            }else{
                documentSnapshots.forEach(doc => {
                    
                    const regenerated = doc.data().hasOwnProperty('regenerated') ? doc.data().regenerated : doc.data().created;
                    const dateEnd = new Date(0); // The 0 there is the key, which sets the date to the epoch
                    dateEnd.setUTCSeconds(regenerated);
                    const dateToday = new Date();
                    const DifferenceInTime = dateToday.getTime() - dateEnd.getTime();
                    const DifferenceInDays = DifferenceInTime / (1000 * 3600 * 24);
                    const outdated = doc.data().hasOwnProperty('regenerated') ? (DifferenceInDays > 13) : (DifferenceInDays > 29);

                    records.push({
                        'id': doc.id,
                        'total': (doc.data().total / 100).toFixed(2),
                        'subTotal': (doc.data().subTotal / 100).toFixed(2),
                        'tax': ((doc.data().tax || 0) / 100).toFixed(2),
                        'amountPaid': (doc.data().amountPaid / 100).toFixed(2),
                        'created': (new Date(doc.data().created * 1000)).toLocaleString(),
                        'outdated': outdated,
                        'hostedInvoiceUrl': doc.data().hostedInvoiceUrl,
                        'currency': doc.data().currency,
                        'status': doc.data().status
                    });
                });
                if(records.length > 0){
                    setRows(rows => rows.concat(records));
                    setQs(documentSnapshots);
                }
            }
            setLoading(false);
        });
    }

    useEffect(() => {
        setBreadcrumb([
            {
                to: "/",
                text: "Home",
                active: false
            },
            {
                to: "/account/"+userData.currentAccount.id+"/",
                text: userData.currentAccount.name + ` (${userData.currentAccount.id})`,
                active: false
            },
            {
                to: null,
                text: "Billing",
                active: true
            }
        ]);
        getInvoices(userData.currentAccount.id, pageSize);
    },[userData, setBreadcrumb]);


    return (
        <>
            <div className="container-fluid">
                <div className="animated fadeIn">
                    <div className="text-right mb-3">
                        {(userData.currentAccount.role === 'admin') &&
                            <>
                                {userData.currentAccount.price > 0 && 
                                    <Link to={"/account/"+userData.currentAccount.id+"/billing/payment-method"} className="btn btn-primary mr-2">Update Payment Method</Link>
                                }
                                <Link to={"/account/"+userData.currentAccount.id+"/billing/plan"} className="btn btn-primary mr-2">Change Subscription Plan</Link>
                                <Link to={"/account/"+userData.currentAccount.id+"/billing/delete"} className="btn btn-danger">Delete Account</Link>
                            </>
                        }
                    </div>
                    {errorMessage !== null && (
                        <AlertPreformatted
                            type="warning"
                            message={errorMessage}
                            dismissible={true}
                            onDismiss={() => setErrorMessage(null)}
                        ></AlertPreformatted>
                    )}
                    <div className="card">
                        <div className="card-header">
                            {title}
                        </div>
                        <div className="card-body">
                            {rows.length > 0 &&
                                <>
                                    <table className="table table-responsive-sm table-hover table-outline">
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col">Invoice ID</th>
                                                <th scope="col">Amount</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Invoice Date</th>
                                                <th scope="col">Invoice URL</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {rows.map((r,i) => 
                                            <tr key={r.id}>
                                                <td>{r.id}</td>
                                                <td>{currency[r.currency].sign}{r.total}</td>
                                                <td>{r.status.toUpperCase()}</td>
                                                <td>{r.created}</td>
                                                <td>
                                                    {r.outdated ? 
                                                        <button disabled={invoiceLoading !== ''} className="btn btn-primary" onClick={() => {getOutdatedInvoice(r.id)}}>{invoiceLoading===r.id ? "Getting Invoice.." : "Retrieve Invoice"}</button>
                                                        : <a href={r.hostedInvoiceUrl} rel="noreferrer" target="_blank" className="btn btn-info">View Invoice</a>
                                                    }
                                                    </td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                </>
                            }
                            {loading?(
                                <Loader text="Loading data..."></Loader>
                            ):(
                                <>
                                {toEnd?(
                                    <span>End of all invoices</span>
                                ):(
                                    <button className="btn btn-primary" onClick={e => {
                                        getInvoices(userData.currentAccount.id, pageSize, qs.docs[qs.docs.length-1]);
                                    }}>View More</button>
                                )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PaymentList;