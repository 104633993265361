import React, {useEffect, useState} from "react";
import { CloudFunctions } from "../../../components/FirebaseAuth/firebase";
import { Button, Form, InputGroup, Tooltip } from "react-bootstrap";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

export const RenameableHeader = ({account, getAccounts}) => {
    const [showRenameForm, setShowRenameForm] = useState(false);
    const [savingAccountName, setSavingAccountName] = useState(false);
    const [selectedAccountName, setSelectedAccountName] = useState("");

    const saveAccountName = async () => {
        setSavingAccountName(true);
        const changeAccountName = CloudFunctions.httpsCallable('oncall');
        await changeAccountName({
            name: "changeAccountName",
            data: {
                accountId: account.id,
                name: selectedAccountName
            }
        })
        await getAccounts();
        setSavingAccountName(false);
        setShowRenameForm(false);
    }

    useEffect(() => {
        setSelectedAccountName(account.name);
    }, [account]);

    const rename_tooltip = (
        <Tooltip id="tooltip">
            Rename Account
        </Tooltip>
    );

    return (
        <>
        {(showRenameForm) ? 
            <>
                <InputGroup>
                    <Form.Control
                        type="text"
                        disabled={savingAccountName}
                        value={selectedAccountName} onChange={(e) => setSelectedAccountName(e.target.value)}
                    />

                    {savingAccountName ? (
                        <Button variant="success" disabled>
                            <i className="fa fa-spinner fa-spin"></i>
                        </Button>
                    ) : (
                        <>
                            <Button variant="success" onClick={() => {saveAccountName()}}>
                                <i className="fa fa-save"></i>
                            </Button>
                            <Button variant="secondary" onClick={() => {setShowRenameForm(false)}}>
                                <i className="fa fa-times"></i>
                            </Button>
                        </>
                    )}
                        
                </InputGroup>
            </>
            :
            <>
                {account.name}{" "}
                <OverlayTrigger placement="top" overlay={rename_tooltip}>
                    <Button variant="light" onClick={() => {setShowRenameForm(true)}}>
                        <i className="fa fa-edit"></i>
                    </Button>
                </OverlayTrigger>
            </>
            }
        </>
    )
}